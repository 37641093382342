import React, { useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import {
	Button,
	Checkbox,
	FormControl,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";
import { get } from "lodash";
import { isActionAllowed } from "components/HasPermission";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CloseIcon from "@material-ui/icons//Close";
import isEmpty from "lodash/isEmpty";
import { fetchUsers } from "../redux/actions/index";
import { updateFilter } from "../redux/reducers/UserFilterReducer";
import { SearchFilter, useQuery } from "../../components/Filter";
import useStyles from "../style";

const statusList = [
	{ code: "ACTIVE", label: "ACTIVE" },
	{ code: "DISABLE", label: "DISABLE" },
];

function UserFilter({ sortByList, typeUser, module }) {
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);

	const filterUsers = {
		userName: "",
		status: [],
		pageNo: 0,
		pageSize: 10,
		sortBy: "",
		typeUser: null,
	};

	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();
	const [status, setStatus] = React.useState([]);
	const [, setSortBy] = React.useState("");
	const [sorted, setSorted] = React.useState(false);

	const handleChangeStatus = (event) => {
		const list = statusList.filter((s) => event.target.value.includes(s.label));
		dispatch(updateFilter({ ...filterUsers, status: list, pageNo: 0, typeUser }));
		setStatus(event.target.value);
		dispatch(fetchUsers({ ...filterUsers, status: list, pageNo: 0, typeUser }));
	};
	const handleChangeSortBy = (event) => {
		setSorted(!isEmpty(event.target.value));
		setSortBy(event.target.value);
		dispatch(updateFilter({ ...filterUsers, pageNo: 0, sortBy: event.target.value, typeUser }));
		dispatch(
			fetchUsers({
				...filterUsers,
				pageNo: 0,
				sortBy: event.target.value,
				typeUser,
			})
		);
	};

	const handleFilterValueChange = () => {
		dispatch(
			updateFilter({
				...filterUsers,
				userName: query.get("q"),
				pageNo: 0,
				typeUser,
			})
		);
		dispatch(
			fetchUsers({
				...filterUsers,
				userName: query.get("q"),
				pageNo: 0,
				typeUser,
			})
		);
	};

	useEffect(() => {
		setTimeout(() => {
			handleFilterValueChange();
		}, 0);
	}, [query.get("q")]);

	return (
		<Grid container direction="column">
			<Grid item xs={2}>
				<SearchFilter
					callback={handleFilterValueChange}
					placeHolder={t("searchUser")}
					style={classes.searchIcon}
					value={query.get("q")}
				/>
			</Grid>
			<Grid item container direction="row" justify="flex-start">
				<Grid container spacing={2} xs={5}>
					<Grid item xs={5}>
						<FormControl fullWidth>
							<InputLabel id="status">{t("status")}</InputLabel>
							<Select
								labelId="sytatus"
								id="status"
								multiple
								value={status}
								onChange={handleChangeStatus}
								input={<Input />}
								renderValue={(selected) => selected.map((s) => t(s)).join(", ")}
							>
								{statusList &&
									statusList.map((row, i) => (
										<MenuItem key={i} value={row.label}>
											<Checkbox checked={status.indexOf(row.label) > -1} />
											<ListItemText primary={t(row.label)} />
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={5}>
						<FormControl fullWidth>
							<InputLabel id="sortBy">{t("sortBy")}</InputLabel>
							<Select
								labelId="sortBy"
								id="sortById"
								onChange={handleChangeSortBy}
								input={<Input />}
								renderValue={(selected) => t(selected)}
							>
								{sorted && (
									<MenuItem value="">
										<Grid container direction="row" style={{ color: "gray" }}>
											<ListItemText primary="Enlever le filtre" />
											<CloseIcon />
										</Grid>
									</MenuItem>
								)}
								{sortByList &&
									sortByList.map((row, i) => (
										<MenuItem key={i} value={row}>
											<ListItemText primary={t(row)} />
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container justify="flex-end">
				<Grid item xs={2}>
					{((typeUser === "USER" &&
						isActionAllowed(currentUserPermissions, "Manage users", "Add")) ||
						(typeUser === "SUPPLIER" &&
							isActionAllowed(currentUserPermissions, "Manage suppliers", "Add")) ||
						(typeUser === "CLIENT" &&
							isActionAllowed(currentUserPermissions, "Manage clients", "Add"))) && (
						<Button
							type="submit"
							fullWidth
							color="secondary"
							variant="contained"
							onClick={() => {
								history.push(`/administration/${module}/add`);
							}}
						>
							{typeUser === "USER"
								? t("add_user_button")
								: typeUser === "SUPPLIER"
								? t("add_supplier_button")
								: typeUser === "GROUPS"
								? t("add_group_button")
								: t("add_client_button")}
						</Button>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

export default UserFilter;
