import React from "react";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutline";
import InsertDriveFileOutlined from "@material-ui/icons/InsertDriveFileOutlined";

import TuneIcon from "@material-ui/icons/Tune";
import Home from "../pages/Home";
import List from "../pages/Invoices/List";
import { List as SuppliersList } from "../pages/Suppliers/List";
import { UsersHome } from "../administration/users";
import Roles from "../redux/constants/roles";

export default {
	invoices: [
		{
			index: 1,
			name: "Dashboard",
			menuPermissionName: "Dashboard invoices",
			path: "/",
			component: () => <Home />,
			icon: <DashboardIcon />,
			hasAccess: [Roles.user, Roles.manager],
		},
		{
			index: 2,
			name: "Administration",
			menuPermissionName: "Administration",
			path: "/administration/invoice/commercial-organization",
			component: () => <UsersHome />,
			icon: <TuneIcon />,
			hasAccess: [Roles.admin],
		},
		{
			index: 3,
			name: "Invoices",
			menuPermissionName: "Invoices list",
			path: "/invoices",
			component: () => <List />,
			icon: <InsertDriveFileOutlined />,
			hasAccess: [Roles.user, Roles.manager],
		},
		{
			index: 5,
			name: "Suppliers",
			menuPermissionName: "Suppliers",
			path: "/supplier",
			component: () => <SuppliersList />,
			icon: <PeopleIcon />,
			hasAccess: [Roles.user, Roles.manager],
		},
	],
};
