import React, { useEffect, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import {
	Button,
	Input,
	NativeSelect,
	IconButton,
	Tooltip,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import { isActionAllowed } from "components/HasPermission";
import EditIcon from "@material-ui/icons/Edit";
import { fetchCommercialOrganizations } from "redux/actions/commercialOrganizations";
import { toast, REGEX } from "utils";
import clsx from "clsx";

import { createUser, fetchCivilities, fetchJobs, fetchUser, updateUser } from "../redux/actions";
import CircularLoader from "../../components/Loaders/CircularLoader";
import ConfirmDialog from "../../components/ConfirmDialog";

import useStyles from "../style";

export const Add = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const { errors, control, handleSubmit, register, setValue } = useForm();
	const history = useHistory();

	const [idUser, setIdUser] = React.useState();
	const [job, setJob] = React.useState("");
	const [commercialOrganization, setCommercialOrganization] = React.useState("");
	const [civility, setCivility] = React.useState("");
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const [userToSave, setUserToSave] = React.useState(null);
	const [affectAll, setAffectAll] = React.useState(false);
	const isAddEditMode = ["edit", "add"].some(
		(i) => new URL(window.location).pathname.indexOf(i) !== -1
	);
	const isEditMode = ["edit"].some((i) => new URL(window.location).pathname.indexOf(i) !== -1);
	const jobs = useSelector(
		({ requests }) => requests.queries.FETCH_USERS_JOBS && requests.queries.FETCH_USERS_JOBS.data
	)?.filter((data) => data.code !== "super_user");
	const commercialOrganizations = useSelector(
		({ requests }) =>
			requests.queries.FETCH_COMMERCIAL_ORGANIZATIONS &&
			requests.queries.FETCH_COMMERCIAL_ORGANIZATIONS.data
	);
	const civilities = useSelector(
		({ requests }) =>
			requests.queries.FETCH_USERS_CIVILITIES && requests.queries.FETCH_USERS_CIVILITIES.data
	)?.filter((civ) => civ.label !== "Ms"); // TODO: remove filter after 1.0 release
	const userTomodify = useSelector(
		({ requests }) => requests.queries.FETCH_USER && requests.queries.FETCH_USER.data
	);

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);

	const handleJobFieldChange = (event) => {
		const { target } = event;
		setJob(target.value);
	};
	const handleCommercialOrganizationFieldChange = (event) => {
		const { target } = event;
		setCommercialOrganization(target.value);
	};
	const handleCivilityFieldChange = (event) => {
		const { target } = event;
		setCivility(target.value);
	};
	const handleChangeAffectAll = (event) => {
		const { target } = event;
		setAffectAll(target.checked);
	};
	useEffect(() => {
		dispatch(fetchJobs());
		dispatch(fetchCivilities());
		dispatch(fetchCommercialOrganizations());
	}, []);
	useEffect(() => {
		if (props.match.params.idUser) {
			setIdUser(props.match.params.idUser);
			dispatch(fetchUser(props.match.params.idUser));
		}
	}, [props.match.params.idUser]);
	useEffect(() => {
		if (props.match.params.idUser && userTomodify) {
			setValue("firstName", userTomodify.firstName);
			setValue("lastName", userTomodify.lastName);
			setValue("email", userTomodify.email);
			setValue("affectAll", userTomodify.affectAll);

			if (get(userTomodify, "affectAll") !== null) setAffectAll(userTomodify.affectAll);
			if (get(userTomodify, "jobTitle.label")) setJob(t(userTomodify.jobTitle.label));
			if (get(userTomodify, "civility.label")) setCivility(t(userTomodify.civility.label));
			if (get(userTomodify, "commercialOrganization.name"))
				setCommercialOrganization(userTomodify.commercialOrganization.name);
			else setCommercialOrganization("");
		}
	}, [userTomodify]);

	useEffect(() => {
		if (jobs && !job) setJob(t(jobs[0].label));
	}, [jobs]);

	const onSubmit = (data) => {
		const selectedJob = jobs.find((s) => data.jobTitle === t(s.label));
		const selectedCivility = civilities.find((s) => data.civility === t(s.label));
		const selectedCommercialOrganization = commercialOrganizations?.find(
			(s) => commercialOrganization === s.name
		);

		data = {
			...data,
			affectAll: affectAll || false,
			jobTitle: selectedJob,
			civility: selectedCivility,
			commercialOrganization: affectAll ? null : selectedCommercialOrganization,
		};
		setUserToSave(data);
		setConfirmObject({
			message: props.match.params.idUser
				? t("admin.user.confirm.message.edit")
				: t("admin.user.confirm.message.add"),
			openConfirm: true,
		});
	};
	const onConfirm = () => {
		if (!confirmObject.cancel) {
			let data = userToSave;
			setConfirmObject({ ...confirmObject, isLoading: true });
			if (props.match.params.idUser) {
				// update mode
				data = { ...data, id: props.match.params.idUser };
				dispatch(
					updateUser(
						props.match.params.idUser,
						data,

						() => {
							setConfirmObject({ message: "", openConfirm: false, isLoading: false });
							toast.success(t("invoice.user.updated.success"));
							setTimeout(() => {
								history.push("/administration/users");
							}, 1000);
						},
						(error) => {
							setConfirmObject({ message: "", openConfirm: false, isLoading: false });
							toast.error(error);
						}
					)
				);
			} else {
				dispatch(
					createUser(
						data,
						() => {
							setConfirmObject({ message: "", openConfirm: false, isLoading: false });
							toast.success(t("sendEmailCreationUserMessage"));
							setTimeout(() => {
								history.push("/administration/users");
							}, 1000);
						},
						(error) => {
							setConfirmObject({ message: "", openConfirm: false, isLoading: false });
							toast.error(error);
						}
					)
				);
			}
		} else {
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			history.push("/administration/users");
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	return (
		<>
			{(props.match.params.idUser && userTomodify) || !props.match.params.idUser ? (
				<Grid
					container
					xs={12}
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.addUserTitle}
				>
					<Grid
						container
						direction="row"
						justify="flex-start"
						spacing={1}
						className={classes.manageUsersTitle}
					>
						<Grid item>
							<GroupIcon fontSize="small" />
						</Grid>
						<Grid item>
							<Typography variant="body2" color="primary">
								{t("manageUsers")} {t(" > ")}{" "}
								{props.match.params.idUser && isAddEditMode
									? t("edit_user")
									: !isAddEditMode && userTomodify
									? t("user.roles.user")
									: t("add_user")}
							</Typography>
						</Grid>
					</Grid>
					{!isAddEditMode && (
						<Grid container justify="flex-start" xs={12}>
							<Grid item xs={2}>
								<Button
									variant="contained"
									fullWidth
									color="secondary"
									className={clsx(classes.backBtn)}
									onClick={() => history.goBack()}
								>
									{t("return")}
								</Button>
							</Grid>
						</Grid>
					)}
					<Grid container item xs={12} justify="center" alignItems="center" alignContent="center">
						<form onSubmit={handleSubmit(onSubmit)}>
							<Grid
								container
								item
								xs={12}
								sm={12}
								className={classes.firstGrid}
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								<Grid
									container
									item
									xs={12}
									className={classes.headerTitle}
									justify="center"
									alignItems="center"
									alignContent="center"
								>
									<Typography variant="h6" color="primary">
										{props.match.params.idUser && isAddEditMode
											? t("edit_user")
											: !isAddEditMode && userTomodify
											? t("user.roles.user")
											: t("add_user")}
									</Typography>
								</Grid>
								<Grid
									container
									item
									xs={10}
									className={clsx(classes.loginContainer, classes.showAllContainerSpacign)}
									spacing={6}
									justify="center"
									alignItems="center"
									alignContent="center"
								>
									{idUser ? (
										<Grid container justify="flex-end" xs={12}>
											{!isEditMode &&
												isActionAllowed(currentUserPermissions, "Manage users", "Edit") && (
													<Tooltip placement="top" title={t("tooltip.action.edit")}>
														<IconButton
															aria-label="edit"
															onClick={() =>
																history.push(
																	`/administration/users/edit/${props.match.params.idUser}`
																)
															}
														>
															<EditIcon />
														</IconButton>
													</Tooltip>
												)}
										</Grid>
									) : (
										<Grid />
									)}
									<Grid container item xs={12} spacing={6}>
										<Grid item xs={4}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("userCivility")}
												<span style={{ color: "red" }}>*</span>

												<br />
												<NativeSelect
													value={civility}
													onChange={handleCivilityFieldChange}
													disabled={!isAddEditMode}
													inputRef={register({
														required: {
															value: true,
															message: t("civility_required"),
														},
													})}
													name="civility"
													fullWidth
												>
													{civilities &&
														civilities.map((elt, i) => (
															<option key={i} value={t(elt.label)}>
																{t(elt.label)}
															</option>
														))}
												</NativeSelect>
												<Typography className={classes.errorMessage}>
													{errors.civility?.message}
												</Typography>
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("lastName")}
												<span style={{ color: "red" }}>*</span>

												<br />

												<Input
													inputRef={register({
														required: {
															value: true,
															message: t("lastName_required"),
														},
														pattern: {
															value: REGEX.FORM_NAMES,
															message: t("lastName_notValid"),
														},
													})}
													defaultValue={
														props.match.params.idUser && userTomodify && userTomodify.lastName
													}
													name="lastName"
													fullWidth
													disabled={!isAddEditMode}
												/>
												<Typography className={classes.errorMessage}>
													{errors.lastName?.message}
												</Typography>
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("firstName")}
												<span style={{ color: "red" }}>*</span>

												<br />
												<Input
													inputRef={register({
														required: {
															value: true,
															message: t("firstName_required"),
														},
														pattern: {
															value: REGEX.FORM_NAMES,
															message: t("firstName_notValid"),
														},
													})}
													name="firstName"
													fullWidth
													disabled={!isAddEditMode}
												/>
											</Typography>
											<Typography className={classes.errorMessage}>
												{errors.firstName?.message}
											</Typography>
										</Grid>
									</Grid>
									<Grid container item xs={12} spacing={6}>
										<Grid item xs={4}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("email")}
												<span style={{ color: "red" }}>*</span>

												<br />

												<Input
													inputRef={register({
														pattern: {
															value: REGEX.EMAIL,
															message: t("email_notValid"),
														},
														required: {
															value: true,
															message: t("email_required"),
														},
													})}
													name="email"
													type="email"
													disabled={props.match.params.idUser || !isAddEditMode}
													fullWidth
												/>
												<Typography className={classes.errorMessage}>
													{errors.email?.message}
												</Typography>
											</Typography>
										</Grid>

										<Grid item xs={4}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("userFunction")}
												<span style={{ color: "red" }}>*</span>

												<br />

												<NativeSelect
													value={job}
													onChange={handleJobFieldChange}
													disabled={!isAddEditMode}
													inputRef={register({
														required: { value: true, message: t("job_required") },
													})}
													name="jobTitle"
													fullWidth
												>
													{jobs &&
														jobs.map((elt, i) => {
															if (!["supplier", "client", "agent_purchase"].includes(elt.label))
																return (
																	<option
																		key={i}
																		selected={
																			props.match.params.idUser &&
																			elt.label === get(userTomodify, "civility.label")
																		}
																		value={t(elt.label)}
																	>
																		{t(elt.label)}
																	</option>
																);
															return null;
														})}
												</NativeSelect>
												<Typography className={classes.errorMessage}>
													{errors.jobTitle?.message}
												</Typography>
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography
												variant="subtitle2"
												align="left"
												color="primary"
												style={{ position: "relative" }}
											>
												{t("user.field.commercialOrganization")}
												<br />
												<NativeSelect
													value={affectAll ? "" : commercialOrganization}
													onChange={handleCommercialOrganizationFieldChange}
													disabled={!isAddEditMode}
													name="commercialOrganization"
													fullWidth
												>
													<option
														key="commercial-organization-option-empty}"
														aria-label="commercialOrganization"
														value=""
													>
														&nbsp;
													</option>
													{commercialOrganizations &&
														commercialOrganizations.map((elt, i) => (
															<option
																key={`commercial-organization-option-${i}`}
																selected={
																	get(userTomodify, "commercialOrganization.name") === elt?.name
																}
																value={elt.name}
															>
																{elt.name}
															</option>
														))}
												</NativeSelect>
												<Typography className={classes.errorMessage}>
													{errors.commercialOrganization?.message}
												</Typography>
												<div className={classes.showAllContainer}>
													<Grid container justify="flex-start" alignItems="center" direction="row">
														<Grid item>
															<FormControlLabel
																control={
																	<Controller
																		name="affectAll"
																		control={control}
																		render={(thisprops) => (
																			<Checkbox
																				disabled={!isAddEditMode}
																				inputRef={register}
																				{...thisprops}
																				checked={affectAll}
																				onChange={handleChangeAffectAll}
																			/>
																		)}
																	/>
																}
																className={classes.formControlLabel}
															/>
														</Grid>
														<Stack direction="row" alignItems="center" spacing={1}>
															<Grid item>{t("user.commercial_organization_showAll")} </Grid>
															<Grid item>
																<Tooltip
																	arrow
																	followCursor
																	placement="top"
																	title={
																		<Grid className={classes.titleGrid}>
																			{t("user.commercial_organization_showAll.description")}
																		</Grid>
																	}
																>
																	<Grid item container spacing={1}>
																		<HelpOutline fontSize="small" />
																	</Grid>
																</Tooltip>
															</Grid>
														</Stack>
													</Grid>
												</div>
											</Typography>
										</Grid>
									</Grid>
								</Grid>

								<Grid
									container
									item
									xs={12}
									spacing={2}
									className={classes.actions}
									justify="center"
									alignItems="center"
									alignContent="center"
								>
									<>
										<Grid item>
											<Button
												color="primary"
												autoFocus
												disabled={!isAddEditMode}
												onClick={() => {
													setConfirmObject({
														message: t("CancelActionMessage"),
														openConfirm: true,
														cancel: true,
													});
													history.push("/administration/users");
												}}
											>
												{t("cancel")}
											</Button>
										</Grid>
										<Grid item>
											<Button
												variant="contained"
												type="submit"
												color="secondary"
												disabled={!isAddEditMode}
											>
												{t("confirm")}
											</Button>
										</Grid>
									</>
								</Grid>
								<ConfirmDialog
									message={confirmObject.message}
									open={confirmObject.openConfirm}
									isLoading={confirmObject.isLoading}
									onConfirm={onConfirm}
									onCancel={onCancel}
								/>
							</Grid>
						</form>
					</Grid>{" "}
				</Grid>
			) : (
				<CircularLoader />
			)}
		</>
	);
};

export default Add;
