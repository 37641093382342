import React, { useEffect } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import Divider from "@material-ui/core/Divider";
import MuiAlert from "@material-ui/lab/Alert";
import { useForm } from "react-hook-form";
import { Tooltip } from "@material-ui/core";
import get from "lodash/get";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { toast } from "utils";
import i18n from "../../i18n";
import { TO_VERIFY, TO_CONTROL } from "../../redux/constants/index";
import {
	createInvoiceApprovalConfirmation,
	fetchInvoiceProtected,
	createInvoiceApprovalRejection,
} from "../../redux/actions/invoiceApproval";
import {
	fetchDocumentInvoice,
	fetchSuppliers,
	updateInvoice,
	fetchRejectionMotifs,
	createInvoiceRejection,
	verifyInvoice,
	topayInvoice,
} from "../../redux/actions/invoices";

import useStyles from "./style";
import AccountingLines from "./AccountingLines";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other} // eslint-disable-line
		>
			{value === index && (
				<Grid container xs={12} style={{ margin: 0 }}>
					{children}
				</Grid>
			)}
		</div>
	);
}
TabPanel.propTypes = {
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	};
}

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />; // eslint-disable-line
}

const DialogReject = ({ open, setOpen, rejectionMotifs, id, submitRejection }) => {
	const { t } = useTranslation();
	const { errors, handleSubmit, register } = useForm();

	const handleCloseRejectPopup = () => {
		setOpen(!open);
	};

	const onSubmit = (data) => {
		const listMotifs = rejectionMotifs && rejectionMotifs.invoiceRejectionMotifList;
		const motif = listMotifs.find((motifParam) => t(motifParam.label) === data.motif);
		submitRejection({ invoiceId: id, motif: motif.code, comment: data.comment }, () => {
			handleCloseRejectPopup();
			toast.error(t("this operation is failed"));
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseRejectPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t("rejectMessage")}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<Autocomplete
						options={rejectionMotifs && rejectionMotifs.invoiceRejectionMotifList}
						getOptionLabel={({ label = "" }) => t(label)}
						id="rejectionReason"
						renderInput={(params) => (
							<div>
								<TextField
									{...params} // eslint-disable-line
									inputRef={register({ required: true })}
									variant="standard"
									label={t("rejectionReason")}
									margin="normal"
									name="motif"
								/>
								{errors.motif && (
									<Typography variant="subtitle2" align="left" color="error">
										{t("this field is mandatory")}
									</Typography>
								)}
							</div>
						)}
					/>
					<TextField
						inputRef={register}
						name="comment"
						id="outlined-multiline-static"
						label={t("rejectionDetails")}
						multiline
						fullWidth
						rows={4}
						defaultValue=""
						variant="outlined"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseRejectPopup} color="secondary" autoFocus>
						{t("cancel")}
					</Button>
					<Button type="submit" color="secondary">
						{t("confirm")}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

const ConfirmationDialog = ({ open, setOpen, submitInvoiceApprove }) => {
	const { t } = useTranslation();
	const { handleSubmit, register } = useForm();

	const handleCloseControlPopup = () => {
		setOpen(!open);
	};

	const onSubmit = (data) => {
		submitInvoiceApprove(data.comment, handleCloseControlPopup(), () => {
			toast.error(t("this operation is failed"));
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseControlPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t("controlMessage")}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<TextField
						inputRef={register}
						name="comment"
						id="outlined-multiline-static"
						label={t("comment")}
						multiline
						fullWidth
						rows={4}
						defaultValue=""
						variant="outlined"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseControlPopup} color="secondary" autoFocus>
						{t("cancel")}
					</Button>
					<Button type="submit" color="secondary">
						{t("confirm")}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

const dataLang = [
	{
		flag: "us",
		key: "en",
		text: "English",
	},
	{
		flag: "fr",
		key: "fr",
		text: "Français",
	},
	{
		flag: "es",
		key: "es",
		text: "Español",
	},
	{
		flag: "ma",
		key: "ar",
		text: "العربية",
	},
	{
		flag: "de",
		key: "de",
		text: "Deutsch",
	},
];

function Detail(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [previewFile, setPreviewFile] = React.useState(null);
	const [mimeType, setMimeType] = React.useState(null);
	const [value, setValue] = React.useState(0);
	const [scValue, setScValue] = React.useState(0);
	const [openRejectPopup, setOpenRejectPopup] = React.useState(false);
	const [openControlPopup, setOpenControlPopup] = React.useState(false);
	const [anchorLangEl, setAnchorLangEl] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [token, setToken] = React.useState("");

	const invoice = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_INVOICE_TO_APPROVE.data")
	);

	const rejectionMotifs = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_REJECTION_MOTIFS.data")
	);
	const [order, setOrder] = React.useState(invoice ? invoice.order : {});

	const changeLanguage = (language) => {
		setAnchorLangEl(null);
		i18n.changeLanguage(language);
	};

	useEffect(() => {
		const uri = new URLSearchParams(window.location.search);
		setToken(uri.get("token"));
		changeLanguage(dataLang.find((lg) => lg.key === uri.get("lang")) ? uri.get("lang") : "fr");
	}, [token]);

	useEffect(() => {
		dispatch(fetchInvoiceProtected(props.match.params.idInvoice, token)).then((res) => {
			if (get(res, "data.order")) {
				setOrder(get(res, "data.order"));
			}
		});
	}, [dispatch, props.match.params.idInvoice, token]);

	useEffect(() => {
		const blob = new Blob([props.document], { type: mimeType });
		const fileURL = URL.createObjectURL(blob);
		setPreviewFile(fileURL);
	}, [props.document]);

	useEffect(() => {
		props.fetchDocumentInvoice(
			props.match.params.idInvoice ? parseFloat(props.match.params.idInvoice) : 0,
			"inv",
			token,
			setMimeType
		);
	}, [token]);

	useEffect(() => {
		dispatch(fetchRejectionMotifs({ token }));
	}, [dispatch, token]);

	const theme = useTheme();

	const handleChangeFirstTab = (event, newValue) => {
		setValue(newValue);
	};
	const handleChangeSecondTab = (event, newScValue) => {
		setScValue(newScValue);
	};

	const minimizeValueDesignation = (v) => (v && v.length > 45 ? `${v.substr(0, 44)}...` : v);
	const minimizeValueCode = (v) => (v && v.length > 9 ? `${v.substr(0, 8)}...` : v);

	const submitRejection = (invoiceRejection) => {
		dispatch(
			createInvoiceApprovalRejection(
				props.match.params.idInvoice,
				invoiceRejection,
				token,
				() => {
					toast.success(t("invoice.detail.reject.success"));
				},
				() => {
					toast.error(t("invoice.detail.reject.failed"));
				}
			)
		);
	};

	const submitInvoiceApprove = (comment) => {
		dispatch(
			createInvoiceApprovalConfirmation(
				props.match.params.idInvoice,
				token,
				{ comment, purchaseNumber: order.orderNo },
				() => {
					toast.success(t("invoice.detail.approved.success"));
				},
				() => {
					toast.error(t("invoice.detail.approved.failed"));
				}
			)
		);
	};

	return (
		<>
			<Grid container xs={12} item>
				<div className={classes.rootAppBar}>
					<AppBar position="static" color="inherit" className={classes.appBar} elevation={24}>
						<Toolbar>
							<Grid
								justify="space-between" // Add it here :)
								container
								spacing={24}
							>
								<Grid item>
									<IconButton
										edge="start"
										color="primary"
										aria-label="open Menu"
										className={classes.menuButton}
									>
										<MenuIcon />
									</IconButton>
								</Grid>
								<Grid item>
									<IconButton onClick={(e) => setAnchorLangEl(e.currentTarget)}>
										<Typography
											className={`flag-icon flag-icon-${
												dataLang.find(({ key }) => key === i18n.language)?.flag
											} flag-icon-squared`}
										/>
									</IconButton>
								</Grid>
							</Grid>
						</Toolbar>
						<Popover
							id={anchorEl ? "settings-popover" : undefined}
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={() => setAnchorEl(null)}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<Box p={2}>Settings</Box>
						</Popover>
						<Menu
							id="langage-menu"
							anchorEl={anchorLangEl}
							keepMounted
							open={Boolean(anchorLangEl)}
							onClose={() => setAnchorLangEl(null)}
						>
							{dataLang.map(({ flag, key, text }, i) => (
								<MenuItem
									key={i}
									onClick={() => changeLanguage(key, setAnchorLangEl)}
									selected={key === i18n.language}
								>
									<ListItemIcon>
										<Typography className={`flag-icon flag-icon-${flag} flag-icon-squared`} />
									</ListItemIcon>
									{text}
								</MenuItem>
							))}
						</Menu>
					</AppBar>
				</div>
			</Grid>

			<Grid container xs={12} style={{ backgroundColor: "#f8f9fa", paddingTop: "20px" }}>
				<Grid container item xs={12}>
					<Grid container item xs={5} className={classes.header}>
						<Grid container xs={6} item alignItems="center">
							<Grid item alignContent="center">
								<Typography variant="h5" color="primary">
									{get(invoice, "invoiceNo")}
								</Typography>
							</Grid>
						</Grid>
						<Grid container xs={6} item alignItems="center" justify="flex-end">
							<Grid item>
								<Chip
									align="right"
									className={classes.alignStatus}
									label={t(get(invoice, "status.label"))}
									color="secondary"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container item xs={12} className={classes.detailsContent} justify="space-evenly">
					<Grid container item xs={5} className={classes.invoiceOrderTabs}>
						<Grid item xs={12}>
							<AppBar position="static" color="white" elevation={0}>
								<Tabs
									value={value}
									onChange={handleChangeFirstTab}
									indicatorColor="secondary"
									textColor="secondary"
									variant="fullWidth"
									aria-label="tab facture"
								>
									{/* eslint-disable-next-line */}
									<Tab label={t("Invoice Details")} {...a11yProps(0)} />
									{/* eslint-disable-next-line */}
									<Tab label={t("Order Details")} {...a11yProps(0)} />
									<Tab label={t("Accounting Lines")} {...a11yProps(2)} />
								</Tabs>
							</AppBar>
							<TabPanel section={1} value={value} index={2} dir={theme.direction}>
								<AccountingLines
									rectify={false}
									invoice={invoice}
									glAccounts={props.glAccounts}
									costCenters={props.costCenters}
									paymentMethods={props.paymentMethods}
								/>
							</TabPanel>
							<TabPanel
								section={1}
								value={value}
								index={0}
								dir={theme.direction}
								className={classes.tabInvoiceContainer}
							>
								<Grid container item xs={12} className={classes.tabInvoice} spacing={4}>
									<Grid container item xs={12} className={classes.tabInvoice} spacing={2}>
										<Grid
											container
											item
											xs={12}
											justify="center"
											alignItems="flex-end"
											spacing={1}
											className={classes.noPaddingY}
										>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("supplier")}
													<br />
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "supplier.label")}
														<Divider />
													</Typography>
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("invoice Number")}

													<br />
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{/* {props.invoicePersisted &&
                              props.invoicePersisted.invoiceNo} */}

														{get(invoice, "invoiceNo")}

														<Divider />
													</Typography>
												</Typography>
											</Grid>
										</Grid>

										<Grid container item xs={12} justify="center" alignItems="flex-end" spacing={2}>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("VAT Number")}
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "supplier.vatNumber")}
														<Divider />
													</Typography>
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("invoice.detail.company_siret")}
													<br />
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "supplier.companySiret")}
														<Divider />
													</Typography>
												</Typography>
											</Grid>
										</Grid>

										<Grid container item xs={12} justify="center" alignItems="flex-end" spacing={1}>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("issueDate")}
													<br />
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "issueDate")}

														<Divider />
													</Typography>
												</Typography>
											</Grid>

											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("dueDate")}
													<br />
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "dueDate")}

														<Divider />
													</Typography>
												</Typography>
											</Grid>
										</Grid>

										<Grid container item xs={12} alignItems="flex-end" spacing={1}>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("orderNo")}
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "purchaseOrderNo")}
														<Divider />
													</Typography>
												</Typography>
											</Grid>

											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("DeliverySlip")}

													<br />
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "deliverySlip")}
														<Divider />
													</Typography>
												</Typography>
											</Grid>
										</Grid>

										<Grid container item xs={12} justify="center" alignItems="flex-end" spacing={1}>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("Delivery address")}

													<br />
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "deliveryAddress")}
														<Divider />
													</Typography>
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("Billing address")}

													<Typography variant="body2" align="left" className={classes.blackColor}>
														{invoice && invoice.billingAddress}
														<Divider />
													</Typography>
												</Typography>
											</Grid>
										</Grid>
										<Grid container item xs={12} alignItems="flex-end" spacing={1}>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("country")}

													<br />
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "country")}
														<Divider />
													</Typography>
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="subtitle2" align="left" color="primary">
													{t("currency")}

													<br />
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "currency")}
														<Divider />
													</Typography>
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								<Grid container item xs={12} className={classes.cardBilling}>
									<Grid
										container
										item
										alignItems="center"
										xs={12}
										className={classes.headerBillingCard}
									>
										<Grid item alignContent="center">
											<Typography variant="subtitle2" align="left" color="primary">
												{t("Commands")}
											</Typography>
										</Grid>
									</Grid>
									<Grid container item alignItems="center" justify="center" xs={12}>
										<TableContainer
											component={Paper}
											elevation={0}
											className={classes.tableContainer}
										>
											<Table aria-label="spanning table">
												<TableHead>
													<TableRow>
														<TableCell>
															<Typography
																className={classes.tableCell1}
																variant="subtitle2"
																align="left"
															>
																{t("itemCode")}
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																className={classes.tableCell3}
																variant="subtitle2"
																align="left"
															>
																{t("designation")}
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																className={classes.tableCell2}
																variant="subtitle2"
																align="left"
															>
																{t("quantity")}
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																className={classes.tableCell1}
																variant="subtitle2"
																align="left"
															>
																{t("unitPrice")}
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																className={classes.tableCell2}
																variant="subtitle2"
																align="left"
															>
																{t("vat")}
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																className={classes.tableCell1}
																variant="subtitle2"
																align="left"
															>
																{t("totalHt")}
															</Typography>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<>
														{get(invoice, "billingLinesDtos") &&
															!isEmpty(invoice.billingLinesDtos) &&
															invoice.billingLinesDtos.map((row) => (
																<TableRow key={row.id}>
																	<>
																		<Tooltip title={row.codeArticle} arrow>
																			<TableCell>
																				<Typography
																					className={classes.tableCell1}
																					variant="subtitle"
																					align="left"
																				>
																					{minimizeValueCode(row.codeArticle)}
																				</Typography>
																			</TableCell>
																		</Tooltip>
																		<Tooltip title={row.designation} arrow>
																			<TableCell>
																				<Typography
																					className={classes.tableCell3}
																					variant="subtitle"
																					align="left"
																				>
																					{minimizeValueDesignation(row.designation)}
																				</Typography>
																			</TableCell>
																		</Tooltip>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle"
																				align="left"
																			>
																				{row.quantity}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle"
																				align="left"
																			>
																				{row.price}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle"
																				align="left"
																			>
																				{row.vat}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle"
																				align="left"
																			>
																				{row.totalHt}
																			</Typography>
																		</TableCell>
																	</>
																</TableRow>
															))}
													</>
													<TableRow>
														<TableCell rowSpan={100} />
														<TableCell rowSpan={100} />
														<TableCell rowSpan={100} />
														<TableCell rowSpan={100} />
													</TableRow>
													{invoice &&
														invoice.additionalAmountsHT &&
														invoice.additionalAmountsHT.map((row) => (
															<TableRow>
																<TableCell align="right"> {t("Total HT")}</TableCell>

																<TableCell align="right">
																	{get(row, "amount")} {get(invoice, "currencySymbol")}
																</TableCell>
															</TableRow>
														))}

													{invoice &&
														invoice.additionalAmountsTVA &&
														invoice.additionalAmountsTVA.map((row) => (
															<TableRow>
																<TableCell align="right"> {t("VAT amount")}</TableCell>

																<TableCell align="right">
																	{get(row, "amount")} {get(invoice, "currencySymbol")}
																</TableCell>
															</TableRow>
														))}

													<TableRow>
														<TableCell align="right">{t("Discount")}</TableCell>
														<TableCell align="right">
															{get(invoice, "discount")} {get(invoice, "currencySymbol")}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align="right">{t("Total amount")}</TableCell>
														<TableCell align="right">
															{get(invoice, "total")} {get(invoice, "currencySymbol")}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align="right">{t("orderNo")}</TableCell>
														<TableCell align="right">
															<Input
																className={classes.resize}
																fullWidth
																onChange={(e) => {
																	const _order = JSON.parse(JSON.stringify(order));
																	_order.orderNo = e.target.value;
																	setOrder(_order);
																}}
																value={get(invoice, "purchaseOrderNo")}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											<>
												{get(invoice, "alertes.alertes") &&
													!isEmpty(invoice.alertes.alertes) &&
													invoice.alertes.alertes.map((row) => (
														<Grid item className={classes.alert}>
															<Alert severity="warning" fullWidth>
																{row.message}
															</Alert>
														</Grid>
													))}
											</>
										</TableContainer>
									</Grid>
								</Grid>
							</TabPanel>
							<TabPanel section={1} value={value} index={1} dir={theme.direction}>
								<Grid container item xs={12} className={classes.orderTab} spacing={4}>
									<Grid container item xs={12} justify="center" alignItems="flex-end" spacing={4}>
										<Grid item xs={6}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("orderNo")}

												<Typography variant="body2" align="left" className={classes.blackColor}>
													{get(invoice, "purchaseOrderNo")}
													<Divider />
												</Typography>
											</Typography>
										</Grid>

										<Grid item xs={6} fullWidth>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("issueDate")}
												<br />
												<Typography variant="body2" align="left" className={classes.blackColor}>
													{get(invoice, "purchaseOrder.issueDate")}

													<Divider />
												</Typography>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</TabPanel>
						</Grid>
					</Grid>
					<Grid container item xs={5}>
						<Grid item xs={12}>
							<>
								<AppBar position="relative" color="default" elevation={0}>
									<Tabs
										className={classes.root}
										value={scValue}
										onChange={handleChangeSecondTab}
										indicatorColor="secondary"
										textColor="secondary"
										variant="fullWidth"
										aria-label="full width tabs example2"
									>
										{/* eslint-disable-next-line */}
										<Tab label={t("Invoice overview")} {...a11yProps(0)} />
									</Tabs>
								</AppBar>
								<TabPanel section={2} value={scValue} index={0} dir={theme.direction}>
									{mimeType === "application/pdf" ? (
										<iframe
											src={previewFile}
											type="application/pdf"
											width="100%"
											height="795px"
											title="invoice"
										/>
									) : (
										<img src={previewFile} className={classes.previewImg} alt="preview" />
									)}
								</TabPanel>
							</>
						</Grid>
					</Grid>
				</Grid>

				<Grid container item xs={12}>
					<Grid container item xs={5} className={classes.header} spacing={2}>
						<Grid item xs={4} style={{ padding: "0 4px 0 0" }}>
							<Button
								variant="contained"
								fullWidth
								className={classes.rejectButton}
								onClick={() => {
									setOpenRejectPopup(true);
								}}
								disabled={
									props.invoicePersisted &&
									props.invoicePersisted.status.code !== TO_VERIFY &&
									props.invoicePersisted.status.code !== TO_CONTROL
								}
							>
								{t("Reject Invoice")}
							</Button>
							<DialogReject
								open={openRejectPopup}
								setOpen={setOpenRejectPopup}
								rejectionMotifs={rejectionMotifs}
								submitRejection={submitRejection}
								id={props.match.params.idInvoice}
							/>
						</Grid>

						<>
							<Grid item xs={4} style={{ padding: "0 0 0 4px" }}>
								<Button
									variant="contained"
									fullWidth
									color="secondary"
									to="/"
									onClick={() => {
										setOpenControlPopup(true);
									}}
									disabled={
										props.invoicePersisted && props.invoicePersisted.status.code !== TO_VERIFY
									}
								>
									{t("Approve")}
								</Button>
								<ConfirmationDialog
									open={openControlPopup}
									setOpen={setOpenControlPopup}
									submitInvoiceApprove={submitInvoiceApprove}
								/>
							</Grid>
						</>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

const mapStateToProps = (state) => {
	const dataQueris = state.requests.queries;
	const dataMutations = state && state.mutations;
	return {
		statusList:
			dataQueris.LIST_FETCH_STATUS &&
			dataQueris.LIST_FETCH_STATUS.data &&
			dataQueris.LIST_FETCH_STATUS.data.invoiceStatusList,
		suppliers: dataQueris.FETCH_SUPPLIERS && dataQueris.FETCH_SUPPLIERS.data,
		supplier: dataQueris.FETCH_SUPPLIER && dataQueris.FETCH_SUPPLIER.data,
		status: dataQueris.FETCH_STATUS && dataQueris.FETCH_STATUS.data,
		paymentTerm: dataQueris.FETCH_PAYMENT_TERM && dataQueris.FETCH_PAYMENT_TERM.data,
		paymentTerms: dataQueris.FETCH_PAYMENT_TERMS && dataQueris.FETCH_PAYMENT_TERMS.data,
		invoicePersisted: dataQueris.FETCH_INVOICE && dataQueris.FETCH_INVOICE.data,
		document: dataQueris.FETCH_DOCUMENT_INVOICE && dataQueris.FETCH_DOCUMENT_INVOICE.data,
		response: dataQueris.RESPONSE && dataQueris.RESPONSE.data,
		rejectionError:
			dataMutations &&
			dataMutations.CREATE_INVOICE_REJECTION &&
			dataMutations.CREATE_INVOICE_REJECTION.error,
	};
};

const mapDispatchToProps = {
	fetchDocumentInvoice,
	fetchSuppliers,
	updateInvoice,
	createInvoiceRejection,
	verifyInvoice,
	topayInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
