import React from "react";
import { useHistory } from "react-router";

import { Typography, Paper, ButtonBase } from "@material-ui/core";
import useStyles from "./style";

export default ({
	title,
	path,
	content,
	isSupplier,
	isClient,
	color = "primary",
	rounded = false,
}) => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<ButtonBase
			onClick={() => {
				if (!isSupplier && !isClient) {
					history.push(`/`);
					history.push(path);
				}
			}}
		>
			<Paper square={!rounded} className={classes.root} elevation={0}>
				<Typography variant="h5" color={color} align="center">
					{title}
				</Typography>
				<Typography variant="body2" align="center">
					{content}
				</Typography>
			</Paper>
		</ButtonBase>
	);
};
