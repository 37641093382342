import React, { useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ImportConfig } from "administration/importConfig";
import InvoiceSettings from "administration/settingsPage/InvoiceSettings";
import OrderSettings from "administration/settingsPage/OrderSettings";
import SuppliersCommercialOrganization from "administration/CommercialOrganization";
import ClientsCommercialOrganization from "administration/CommercialOrganization/client";
import { isMenuAllowed } from "components/HasPermission";

import get from "lodash/get";
import useStyles from "../style";
import SubPageContainer from "../../components/SubPageContainer";
import SubPagePanel from "../../components/SubPagePanel";
import { fetchUsers } from "../redux/actions";
import { List } from "./List";
import { ListSuppliers } from "./ListSuppliers";
import { ListClients } from "./ListClients";
import { ListSupperUsers } from "./ListSupperUsers";
import { ExtractionLevelSetting } from "../extractionLevelSettings";
import { isSupperUser } from "../../utils";
import ListSuppliersSetting from "../suppliers/List";
import ListClientsSetting from "../clients/List";

function a11yProps(index) {
	return {
		value: index,
		id: `supplier-detail-tab-${index}`,
		"aria-controls": `supplier-detail-tabpanel-${index}`,
	};
}

export const UsersHome = () => {
	const isUserModify = useSelector(({ appState }) => appState.isUserModify);
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const routes = [
		{ id: 0, url: "/administration/invoice/commercial-organization" },
		{ id: 1, url: "/administration/users" },
		{ id: 2, url: "/administration/suppliers" },
		{ id: 3, url: "/administration/invoice/settings" },
		{ id: 4, url: "/administration/supplier-settings" },
		{ id: 5, url: "/administration/invoice/extraction-settings" },
		{ id: 6, url: "/administration/invoice/import-config" },
		{ id: 7, url: "/administration/workflow" },
		{ id: 8, url: "/administration/su" },
	];
	const routesOrder = [
		{ id: 0, url: "/administration/order/commercial-organization" },
		{ id: 0, url: "/administration/users" },
		{ id: 0, url: "/administration/clients" },
		{ id: 0, url: "/administration/order/settings" },
		{ id: 0, url: "/administration/client-settings" },
		{ id: 0, url: "/administration/order/extraction-settings" },
		{ id: 0, url: "/administration/order/import-config" },
		{ id: 0, url: "/administration/su" },
	];

	const history = useHistory();
	const [value, setValue] = React.useState(
		isOrderEnv
			? routesOrder.map((route) => route.url).indexOf(history.location.pathname)
			: routes.map((route) => route.url).indexOf(history.location.pathname)
	);

	const [tempFlag] = React.useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		history.push(isOrderEnv ? routesOrder[newValue].url : routes[newValue].url);
	};

	useEffect(() => {
		dispatch(fetchUsers());
	}, []);
	useEffect(() => {
		setValue(
			isOrderEnv
				? routesOrder.map((route) => route.url).indexOf(history.location.pathname)
				: routes.map((route) => route.url).indexOf(history.location.pathname)
		);
	});
	return (
		<Grid item xs={12} sm={12} className={classes.firstGrid}>
			{!isOrderEnv ? (
				<SubPageContainer>
					<Paper position="static" className={classes.appBar}>
						{!isUserModify && (
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Assets"
								className={classes.tabs}
								scrollButtons="auto"
								variant="scrollable"
							>
								{isMenuAllowed(currentUserPermissions, "Commercial Organization") && (
									<Tab
										label={t("admin.commercialOrganization")}
										{...a11yProps(0)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Manage users") && (
									<Tab
										label={t("manageUsers")}
										{...a11yProps(1)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Manage suppliers") && (
									<Tab
										label={t("manageSuppliers")}
										{...a11yProps(2)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Invoice settings") && (
									<Tab
										label={t("admin.settings")}
										{...a11yProps(3)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Invoice Extraction levels") && (
									<Tab
										label={t("admin.level-settings")}
										{...a11yProps(5)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Invoice import configuration") && (
									<Tab
										label={t("admin.import-config")}
										{...a11yProps(6)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isSupperUser(get(currentUser, "roles")) && (
									<Tab
										label={t("managerSuperusers")}
										{...a11yProps(8)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{tempFlag && (
									<>
										<Tab
											label={t("manageGroups")}
											{...a11yProps(1)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
										<Tab
											label={t("manageRoles")}
											{...a11yProps(2)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
										<Tab
											label={t("manageWorkflow")}
											{...a11yProps(2)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
										<Tab
											label={t("manageLicenseAddOn")}
											{...a11yProps(2)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
									</>
								)}
							</Tabs>
						)}
					</Paper>
					{isMenuAllowed(currentUserPermissions, "Commercial Organization") && (
						<SubPagePanel value={value} index={0}>
							<Grid container xs={12} className={classes.firstGrid}>
								<SuppliersCommercialOrganization />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Manage users") && (
						<SubPagePanel value={value} index={1}>
							<Grid container xs={12} className={classes.firstGrid}>
								<List />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Manage suppliers") && (
						<SubPagePanel value={value} index={2}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ListSuppliers />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Invoice settings") && (
						<SubPagePanel value={value} index={3}>
							<Grid container xs={12} className={classes.firstGrid}>
								<InvoiceSettings />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Suppliers training") && (
						<SubPagePanel value={value} index={4} className="h-100">
							<Grid container xs={12} className={classes.firstGrid}>
								<ListSuppliersSetting />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Invoice Extraction levels") && (
						<SubPagePanel value={value} index={5}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ExtractionLevelSetting />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Invoice import configuration") && (
						<SubPagePanel value={value} index={6}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ImportConfig />
							</Grid>
						</SubPagePanel>
					)}
					{isSupperUser(get(currentUser, "roles")) && (
						<SubPagePanel value={value} index={8}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ListSupperUsers />
							</Grid>
						</SubPagePanel>
					)}
				</SubPageContainer>
			) : (
				<SubPageContainer>
					<Paper position="static" className={classes.appBar}>
						{!isUserModify && (
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Assets"
								className={classes.tabs}
								scrollButtons="auto"
								variant="scrollable"
							>
								{isMenuAllowed(currentUserPermissions, "Commercial Organization") && (
									<Tab
										label={t("admin.commercialOrganization")}
										{...a11yProps(0)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Manage users") && (
									<Tab
										label={t("manageUsers")}
										{...a11yProps(1)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Manage clients") && (
									<Tab
										label={t("manageClients")}
										{...a11yProps(2)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Order settings") && (
									<Tab
										label={t("admin.settings")}
										{...a11yProps(3)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Clients training") && (
									<Tab
										label={t("clientTraining")}
										{...a11yProps(4)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Order Extraction levels") && (
									<Tab
										label={t("admin.level-settings")}
										{...a11yProps(5)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isMenuAllowed(currentUserPermissions, "Order import configuration") && (
									<Tab
										label={t("admin.import-config")}
										{...a11yProps(6)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{isSupperUser(get(currentUser, "roles")) && (
									<Tab
										label={t("managerSuperusers")}
										{...a11yProps(7)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
							</Tabs>
						)}
					</Paper>
					{isMenuAllowed(currentUserPermissions, "Commercial Organization") && (
						<SubPagePanel value={value} index={0}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ClientsCommercialOrganization />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Manage users") && (
						<SubPagePanel value={value} index={1}>
							<Grid container xs={12} className={classes.firstGrid}>
								<List />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Manage clients") && (
						<SubPagePanel value={value} index={2}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ListClients />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Order settings") && (
						<SubPagePanel value={value} index={3}>
							<Grid container xs={12} className={classes.firstGrid}>
								<OrderSettings />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Clients training") && (
						<SubPagePanel value={value} index={4} className="h-100">
							<Grid container xs={12} className={classes.firstGrid}>
								<ListClientsSetting />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Order Extraction levels") && (
						<SubPagePanel value={value} index={5}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ExtractionLevelSetting />
							</Grid>
						</SubPagePanel>
					)}
					{isMenuAllowed(currentUserPermissions, "Order import configuration") && (
						<SubPagePanel value={value} index={6}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ImportConfig />
							</Grid>
						</SubPagePanel>
					)}
					{isSupperUser(get(currentUser, "roles")) && (
						<SubPagePanel value={value} index={7}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ListSupperUsers />
							</Grid>
						</SubPagePanel>
					)}
				</SubPageContainer>
			)}
		</Grid>
	);
};

export default UsersHome;
