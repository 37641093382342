import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	headerPath: {
		marginTop: "15px",
	},
	headerBreadCumb: {
		marginBottom: "15px",
	},
	boldText: {
		fontWeight: "bold",
	},
	root: {
		width: "100%",
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	formContainer: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: "3px",
	},
	gridRow: {
		marginTop: "5px",
		marginBottom: "5px",
		maxHeight: "100px",
	},
	validation_error: {
		color: "red",
	},
	ImageProfil: {
		textAlign: "center",
	},
}));