import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TableContainer, Table, TableHead, TableBody, Typography } from "@material-ui/core";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { currencyFormat } from "utils";
import useStyles from "../../style";
import { updateFormulaire } from "../../../../../redux/formulaireReducer";

function AmountsComponent({ formulaire, rectify, setLocation, invoice, fromDigitalInvoice }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isDigitalInvoice = get(invoice, "digitalInvoice");

	const updateSelectedItem = (value) => {
		dispatch(updateFormulaire({ ...formulaire, selectedItem: value }));
	};

	const totalTva = invoice?.additionalAmountsTVA?.reduce((a, v) => a + v.amount, 0);
	const [openTva, setOpenTva] = React.useState(false);
	const isEmptyOrNull = (value) => value === null || value === "";
	useEffect(() => {
		if (formulaire.invoiceDto.additionalAmountsHT.length === 0) {
			const _formulaire = JSON.parse(JSON.stringify(formulaire));
			_formulaire.invoiceDto.additionalAmountsHT.push({
				amount: {},
				type: "HT",
				principal: false,
			});
			dispatch(updateFormulaire(_formulaire));
		}
		if (formulaire.invoiceDto.additionalAmountsTVA.length === 0) {
			const _formulaire = JSON.parse(JSON.stringify(formulaire));
			_formulaire.invoiceDto.additionalAmountsTVA.push({
				amount: {},
				type: "VAT",
				principal: false,
				amountsTvaDetails: { percentage: {}, base: {} },
			});
			dispatch(updateFormulaire(_formulaire));
		}
		if (formulaire.invoiceDto.additionalAmountsCharge.length === 0) {
			const _formulaire = JSON.parse(JSON.stringify(formulaire));
			_formulaire.invoiceDto.additionalAmountsCharge.push({
				amount: {},
				type: "CHARGE",
				principal: false,
			});
			dispatch(updateFormulaire(_formulaire));
		}
	}, [formulaire.invoiceDto]);
	return (
		<TableContainer>
			<Table className={classes.AmountsTab}>
				{formulaire.invoiceDto.additionalAmountsHT.map((row, index) => (
					<TableRow>
						<TableCell align="left" className={clsx(rectify && classes.rectifyHover)}>
							{t("Total HT ")}
							{index !== 0 ? index + 1 : ""}
						</TableCell>
						<TableCell align="right">
							<Input
								className={clsx(
									"classes.resize",
									isEmptyOrNull(formulaire.invoiceDto.additionalAmountsHT[index].amount.value) &&
										classes.emptyInput &&
										isDigitalInvoice
								)}
								fullWidth
								onChange={(e) => {
									const _formulaire = JSON.parse(JSON.stringify(formulaire));
									_formulaire.invoiceDto.additionalAmountsHT[index].amount.value = e.target.value;
									if (isEmptyOrNull(e.target.value)) {
										_formulaire.invoiceDto.additionalAmountsHT[index].amount.location = {
											top: null,
											right: null,
											left: null,
											bottom: null,
										};
									}
									dispatch(updateFormulaire(_formulaire));
								}}
								disabled={invoice && isDigitalInvoice && fromDigitalInvoice?.additionalAmountsHT}
								onClick={() => {
									updateSelectedItem(`additionalAmountsht.${index}.amount`);
									setLocation(formulaire.invoiceDto.additionalAmountsHT[index].amount.location);
								}}
								value={
									isDigitalInvoice && fromDigitalInvoice?.additionalAmountsHT
										? get(invoice, "additionalAmountsHT")[0].amount
										: formulaire.invoiceDto.additionalAmountsHT[index].amount.value
								}
							/>
						</TableCell>
						{index === 0 ? (
							<IconButton
								aria-label="add"
								disabled={invoice && isDigitalInvoice && fromDigitalInvoice?.additionalAmountsHT}
								onClick={() => {
									if (rectify) {
										const _formulaire = JSON.parse(JSON.stringify(formulaire));
										_formulaire.invoiceDto.additionalAmountsHT.push({
											amount: {},
											type: "HT",
											principal: false,
										});
										dispatch(updateFormulaire(_formulaire));
									}
								}}
							>
								<AddIcon />
							</IconButton>
						) : (
							<IconButton
								aria-label="add"
								onClick={() => {
									const _formulaire = JSON.parse(JSON.stringify(formulaire));
									_formulaire.invoiceDto.additionalAmountsHT.splice(index, 1);
									dispatch(updateFormulaire(_formulaire));
								}}
							>
								<DeleteOutline />
							</IconButton>
						)}
					</TableRow>
				))}
				<TableRow>
					<TableCell align="left" className={clsx(rectify && classes.rectifyHover)}>
						{t("VAT amount")}
					</TableCell>

					<TableCell align="left">
						<Typography variant="body2" align="left" className={classes.blackColor}>
							{invoice?.additionalAmountsTVA && currencyFormat(totalTva)}
						</Typography>
					</TableCell>
					<IconButton
						onClick={() => {
							setOpenTva(!openTva);
						}}
					>
						{!openTva ? <ArrowDropDown /> : <ArrowDropUp />}
					</IconButton>
				</TableRow>
				{openTva && (
					<TableRow>
						<TableCell colSpan={3} style={{ borderBottom: "none", marginLeft: 0 }} align="left">
							<Table aria-label="customized table">
								<TableHead>
									<TableRow>
										<TableCell align="center">{t("Base ")}</TableCell>
										<TableCell align="left" style={{ width: 80 }}>
											{t("Associated VAT amount")}
										</TableCell>
										<TableCell align="center">{t("% applied")}</TableCell>
										<TableCell align="right" />
									</TableRow>
								</TableHead>
								<TableBody>
									{formulaire.invoiceDto.additionalAmountsTVA.map((row, index) => (
										<TableRow>
											<TableCell className={classes.tableCell1} variant="subtitle" align="left">
												<Input
													className={clsx(
														"classes.resize",
														isEmptyOrNull(
															formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails
																?.base.value
														) &&
														classes.emptyInput &&
														isDigitalInvoice
													)}
													fullWidth
													onChange={(e) => {
														const _formulaire = JSON.parse(JSON.stringify(formulaire));
														if (
															_formulaire.invoiceDto.additionalAmountsTVA[index]
																.amountsTvaDetails === null
														)
															_formulaire.invoiceDto.additionalAmountsTVA[
																index
															].amountsTvaDetails = { percentage: {}, base: {} };

														_formulaire.invoiceDto.additionalAmountsTVA[
															index
														].amountsTvaDetails.base.value = e.target.value;

														if (isEmptyOrNull(e.target.value)) {
															_formulaire.invoiceDto.additionalAmountsTVA[
																index
															].amountsTvaDetails.base.location = {
																top: null,
																right: null,
																left: null,
																bottom: null,
															};
														}
														dispatch(updateFormulaire(_formulaire));
													}}
													onClick={() => {
														updateSelectedItem(
															`additionalAmountstva.${index}.amountsTvaDetails.base`
														);
														setLocation(
															formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails
																?.base.location
														);
													}}
													disabled={
														invoice &&
														isDigitalInvoice &&
														fromDigitalInvoice?.additionalAmountsTVA.amountsTvaDetails
													}
													value={
														isDigitalInvoice &&
															fromDigitalInvoice?.additionalAmountsTVA.amountsTvaDetails?.base
															? get(invoice, "tvaDetailsDto.base")[0].amount
															: formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails
																?.base.value
													}
												/>
											</TableCell>
											<TableCell align="center" className={classes.tableCell2} variant="subtitle">
												<Input
													className={clsx(
														"classes.resize",
														isEmptyOrNull(
															formulaire.invoiceDto.additionalAmountsTVA[index].amount.value
														) &&
														classes.emptyInput &&
														isDigitalInvoice
													)}
													fullWidth
													onChange={(e) => {
														const _formulaire = JSON.parse(JSON.stringify(formulaire));
														_formulaire.invoiceDto.additionalAmountsTVA[index].amount.value =
															e.target.value;
														if (isEmptyOrNull(e.target.value)) {
															_formulaire.invoiceDto.additionalAmountsTVA[index].amount.location = {
																top: null,
																right: null,
																left: null,
																bottom: null,
															};
														}
														dispatch(updateFormulaire(_formulaire));
													}}
													onClick={() => {
														updateSelectedItem(`additionalAmountstva.${index}.amount`);
														setLocation(
															formulaire.invoiceDto.additionalAmountsTVA[index].amount.location
														);
													}}
													disabled={
														invoice && isDigitalInvoice && fromDigitalInvoice?.additionalAmountsTVA
													}
													value={
														isDigitalInvoice && fromDigitalInvoice?.additionalAmountsTVA
															? get(invoice, "additionalAmountsTVA")[0].amount
															: formulaire.invoiceDto.additionalAmountsTVA[index].amount.value
													}
												/>
											</TableCell>
											<TableCell align="center" className={classes.tableCell2} variant="subtitle">
												<Input
													className={clsx(
														"classes.resize	",
														isEmptyOrNull(
															formulaire.invoiceDto.additionalAmountsTVA[index]
																.amountsTvaDetailsOCRDto?.percentage.value
														) &&
														classes.emptyInput &&
														isDigitalInvoice
													)}
													fullWidth
													onChange={(e) => {
														const _formulaire = JSON.parse(JSON.stringify(formulaire));
														if (
															_formulaire.invoiceDto.additionalAmountsTVA[index]
																.amountsTvaDetails === null
														)
															_formulaire.invoiceDto.additionalAmountsTVA[
																index
															].amountsTvaDetails = { percentage: {}, base: {} };

														_formulaire.invoiceDto.additionalAmountsTVA[
															index
														].amountsTvaDetails.percentage.value = e.target.value;
														if (isEmptyOrNull(e.target.value)) {
															_formulaire.invoiceDto.additionalAmountsTVA[
																index
															].amountsTvaDetails.percentage.location = {
																top: null,
																right: null,
																left: null,
																bottom: null,
															};
														}
														dispatch(updateFormulaire(_formulaire));
													}}
													onClick={() => {
														updateSelectedItem(
															`additionalAmountstva.${index}.amountsTvaDetails.percentage`
														);
														setLocation(
															formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails
																?.percentage.location
														);
													}}
													disabled={
														invoice &&
														isDigitalInvoice &&
														fromDigitalInvoice?.additionalAmountsTVA.amountsTvaDetails
													}
													value={
														isDigitalInvoice &&
															fromDigitalInvoice?.additionalAmountsTVA.amountsTvaDetails?.percentage
															? get(invoice, "tvaDetailsDto.base")[0].amount
															: formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails
																?.percentage.value
													}
												/>
											</TableCell>
											<TableCell align="right" className={classes.tableCell2} variant="subtitle">
												{index === 0 ? (
													<IconButton
														aria-label="add"
														onClick={() => {
															if (rectify) {
																const _formulaire = JSON.parse(JSON.stringify(formulaire));
																_formulaire.invoiceDto.additionalAmountsTVA.push({
																	amount: {},
																	type: "VAT",
																	principal: false,
																	amountsTvaDetails: { percentage: {}, base: {} },
																});
																dispatch(updateFormulaire(_formulaire));
															}
														}}
														disabled={
															invoice &&
															isDigitalInvoice &&
															fromDigitalInvoice?.additionalAmountsTVA
														}
													>
														<AddIcon />
													</IconButton>
												) : (
													<IconButton
														aria-label="add"
														onClick={() => {
															const _formulaire = JSON.parse(JSON.stringify(formulaire));
															_formulaire.invoiceDto.additionalAmountsTVA.splice(index, 1);
															dispatch(updateFormulaire(_formulaire));
														}}
													>
														<DeleteOutline />
													</IconButton>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableCell>
					</TableRow>
				)}
				<TableRow>
					<TableCell align="left" className={clsx(rectify && classes.rectifyHover)}>
						{t("Prepay amount")}
					</TableCell>
					<TableCell align="right">
						<Input
							className={clsx(
								"classes.resize",
								isEmptyOrNull(formulaire.invoiceDto.prepayAmount.value) &&
								classes.emptyInput &&
								isDigitalInvoice
							)}
							fullWidth
							onChange={(e) => {
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.invoiceDto.prepayAmount.value = e.target.value;

								if (isEmptyOrNull(e.target.value)) {
									_formulaire.invoiceDto.prepayAmount.location = {
										top: null,
										right: null,
										left: null,
										bottom: null,
									};
								}
								dispatch(updateFormulaire(_formulaire));
								console.log("lel", _formulaire);
							}}
							disabled={invoice && isDigitalInvoice && fromDigitalInvoice?.prepayAmount}
							onClick={() => {
								updateSelectedItem(`prepayAmount`);
								setLocation(formulaire.invoiceDto.prepayAmount);
							}}
							value={
								isDigitalInvoice && fromDigitalInvoice?.prepayAmount
									? get(invoice, "prepayAmount")
									: formulaire.invoiceDto.prepayAmount.value
							}
						/>
					</TableCell>
				</TableRow>

				{formulaire.invoiceDto.additionalAmountsCharge.map((row, index) => (
					<TableRow>
						<TableCell align="left" className={clsx(rectify && classes.rectifyHover)}>
							{t("invoice_charge")}
							{index !== 0 ? index + 1 : ""}
						</TableCell>
						<TableCell align="right">
							<Input
								className={clsx(
									"classes.resize",
									isEmptyOrNull(
										formulaire.invoiceDto.additionalAmountsCharge[index].amount.value
									) &&
									classes.emptyInput &&
									isDigitalInvoice
								)}
								fullWidth
								onChange={(e) => {
									const _formulaire = JSON.parse(JSON.stringify(formulaire));
									_formulaire.invoiceDto.additionalAmountsCharge[index].amount.value =
										e.target.value;
									if (isEmptyOrNull(e.target.value)) {
										_formulaire.invoiceDto.additionalAmountsCharge[index].amount.location = {
											top: null,
											right: null,
											left: null,
											bottom: null,
										};
									}
									dispatch(updateFormulaire(_formulaire));
								}}
								onClick={() => {
									updateSelectedItem(`additionalAmountscharge.${index}.amount`);
									setLocation(formulaire.invoiceDto.additionalAmountsCharge[index].amount.location);
								}}
								disabled={
									invoice &&
									isDigitalInvoice &&
									(fromDigitalInvoice?.additionalAmountsCharge || fromDigitalInvoice?.total)
								}
								value={
									isDigitalInvoice && fromDigitalInvoice?.additionalAmountsCharge
										? get(invoice, "additionalAmountsCharge")[0].amount
										: formulaire.invoiceDto.additionalAmountsCharge[index].amount.value
								}
							/>
						</TableCell>
						{index === 0 ? (
							<IconButton
								aria-label="add"
								onClick={() => {
									if (rectify) {
										const _formulaire = JSON.parse(JSON.stringify(formulaire));
										_formulaire.invoiceDto.additionalAmountsCharge.push({
											amount: {},
											type: "CHARGE",
											principal: false,
										});
										dispatch(updateFormulaire(_formulaire));
									}
								}}
								disabled={
									invoice &&
									isDigitalInvoice &&
									(fromDigitalInvoice?.additionalAmountsCharge || fromDigitalInvoice?.total)
								}
							>
								<AddIcon />
							</IconButton>
						) : (
							<IconButton
								aria-label="add"
								onClick={() => {
									const _formulaire = JSON.parse(JSON.stringify(formulaire));
									_formulaire.invoiceDto.additionalAmountsCharge.splice(index, 1);
									dispatch(updateFormulaire(_formulaire));
								}}
							>
								<DeleteOutline />
							</IconButton>
						)}
					</TableRow>
				))}
				<TableRow>
					<TableCell align="left" className={clsx(rectify && classes.rectifyHover)}>
						{t("Discount")}
					</TableCell>
					<TableCell align="right">
						<Input
							className={clsx(
								"classes.resize",
								rectify &&
								isEmptyOrNull(formulaire.invoiceDto.discount.value) &&
								classes.emptyInput &&
								isDigitalInvoice
							)}
							fullWidth
							onChange={(e) => {
								console.log("leil", e.target.value);
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.invoiceDto.discount.value = e.target.value;

								dispatch(updateFormulaire(_formulaire));
							}}
							onClick={() => {
								updateSelectedItem("discount");
								setLocation(formulaire.invoiceDto.discount.location);
							}}
							disabled={
								invoice &&
								isDigitalInvoice &&
								(fromDigitalInvoice?.discount || fromDigitalInvoice?.total)
							}
							value={
								isDigitalInvoice && fromDigitalInvoice?.discount
									? get(invoice, "discount")
									: formulaire.invoiceDto.discount.value
							}
						/>
					</TableCell>
				</TableRow>

				<TableRow>
					<TableCell align="left" className={clsx(rectify && classes.rectifyHover)}>
						{t("TTC amount")}
					</TableCell>
					<TableCell align="right">
						<Input
							className={clsx(
								"classes.resize",
								rectify &&
								isEmptyOrNull(formulaire.invoiceDto.total.value) &&
								classes.emptyInput &&
								isDigitalInvoice
							)}
							fullWidth
							onChange={(e) => {
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.invoiceDto.total.value = e.target.value;
								if (isEmptyOrNull(e.target.value)) {
									_formulaire.invoiceDto.total.location = {
										top: null,
										right: null,
										left: null,
										bottom: null,
									};
								}
								dispatch(updateFormulaire(_formulaire));
							}}
							onClick={() => {
								updateSelectedItem("total");
								setLocation(formulaire.invoiceDto.total.location);
							}}
							disabled={invoice && isDigitalInvoice && fromDigitalInvoice?.total}
							value={
								isDigitalInvoice && fromDigitalInvoice?.total
									? get(invoice, "total")
									: formulaire.invoiceDto.total.value
							}
						/>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell align="left" className={clsx(rectify && classes.rectifyHover)}>
						{t("Net payable")}
					</TableCell>
					<TableCell align="right">
						<Input
							className={clsx(
								"classes.resize",
								isEmptyOrNull(formulaire.invoiceDto.netToPay.value) &&
								classes.emptyInput &&
								isDigitalInvoice
							)}
							fullWidth
							onChange={(e) => {
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.invoiceDto.netToPay.value = e.target.value;

								if (isEmptyOrNull(e.target.value)) {
									_formulaire.invoiceDto.netToPay.location = {
										top: null,
										right: null,
										left: null,
										bottom: null,
									};
								}
								dispatch(updateFormulaire(_formulaire));
							}}
							disabled={invoice && isDigitalInvoice && fromDigitalInvoice?.netToPay}
							onClick={() => {
								updateSelectedItem(`netToPay`);
								setLocation(formulaire.invoiceDto.netToPay);
							}}
							value={
								isDigitalInvoice && fromDigitalInvoice?.netToPay
									? get(invoice, "netToPay")
									: formulaire.invoiceDto.netToPay.value
							}
						/>
					</TableCell>
				</TableRow>
			</Table>
		</TableContainer>
	);
}

export default AmountsComponent;
