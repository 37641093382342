import React, { useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { SearchFilter, useQuery } from "components/Filter";
import { updateFilter } from "administration/redux/reducers/UserFilterReducer";
import { HasPermission } from "components/HasPermission";
import useStyles from "../Style";

const CommercialOrganizationFilter = ({ module }) => {
	const filterUsers = useSelector(({ filterUsers: i }) => i);
	const history = useHistory();
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const query = useQuery();

	const handleFilterValueChange = () => {
		dispatch(
			updateFilter({
				...filterUsers,
				userName: query.get("q"),
				pageNo: 0,
			})
		);
	};

	useEffect(() => {
		setTimeout(() => {
			handleFilterValueChange();
		}, 0);
	}, [query.get("q")]);

	return (
		<Grid container justify="space-between" direction="column">
			<Grid item xs={2}>
				<SearchFilter
					callback={handleFilterValueChange}
					placeHolder={t("searchUser")}
					style={classes.searchIcon}
					value={query.get("q")}
				/>
			</Grid>
			<Grid item container justify="flex-end">
				<Grid item>
					<HasPermission menuName="Commercial Organization" actionName="Add">
						<Button
							type="submit"
							fullWidth
							color="secondary"
							variant="contained"
							onClick={() => {
								history.push(`/administration/${module}/add`);
							}}
						>
							{t("add_group_button")}
						</Button>
					</HasPermission>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default CommercialOrganizationFilter;
