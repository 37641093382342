import React, { useEffect, useCallback, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import GetAppIcon from "@material-ui/icons/GetApp";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CloseIcon from "@material-ui/icons//Close";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import LaunchIcon from "@material-ui/icons/Launch";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import debounce from "lodash/debounce";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { toast } from "utils";
import { DateRange } from "@material-ui/icons";
import { Popover, Box } from "@material-ui/core";

import ConfirmDialog from "components/ConfirmDialog";
import LoadingButton from "components/LoadingButton";
import { HasPermission, isActionAllowed } from "components/HasPermission";
import { SearchFilter } from "../../../../../components/Filter";

import useStyles from "../../style";

import {
	deleteInvoices,
	downloadInvoices,
	fetchInvoices,
	fetchListStatus,
	invoicesTotalAmounts,
	downloadInvoicesExcel,
	downloadInvoicesXML,
	relaunchInvoices,
	exportAccountingEntriesExcel,
	fetchCountInvoices,
	fetchInvoiceCuttings,
} from "../../../../../redux/actions/invoices";
import { initFilter, updateFilter, initialFilter } from "../../../../../redux/filterReducer";
import DateRangePicker from "../DateRangePicker";

const columns = ["invoiceNo", "issueDate", "updatedAt", "supplier"];
const ISSUE_DATE = "issueDate";
const SAVE_DATE = "saveDate";
const CREATE_DATE = "createdDate";

// DatePicker.overrideReactDatePickerDefaultImport({
// 	dateFns: dateFns,
// });

function Filter(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [dateFilterAnchorEl, setDateFilterAnchorEl] = React.useState(null);

	const filterInvoices = useSelector(({ filterInvoices: i }) => i);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);
	const idInvoices = useSelector(
		({ requests }) =>
			requests.queries.LIST_FETCH_INVOICE &&
			requests.queries.LIST_FETCH_INVOICE.data &&
			requests.queries.LIST_FETCH_INVOICE.data.content
	);

	const classes = useStyles();
	const { t } = useTranslation();
	const [searchField, setSearchField] = React.useState("");
	const [sortBy, setSortBy] = React.useState("");

	const [startIssueDate, setStartIssueDate] = React.useState(null);
	const [endIssueDate, setEndIssueDate] = React.useState(null);
	const [startSaveDate, setStartSaveDate] = React.useState(null);
	const [endSaveDate, setEndSaveDate] = React.useState(null);
	const [startCreatedDate, setStartCreatedDate] = React.useState(null);
	const [endCreatedDate, setEndCreatedDate] = React.useState(null);

	const [checkedAll, setCheckedAll] = React.useState(false);
	const [checkedindeterminate, setCheckedindeterminate] = React.useState(false);
	// const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
	const [selectAll, setSelectAll] = React.useState(false);
	const [selectedPeriodExported, setSelectedPeriodExported] = React.useState("");
	const [selectedPeriodIntegration, setSelectedPeriodIntegration] = React.useState("");
	const [deleteAll, setDeleteAll] = React.useState(false);
	const [filterContext] = React.useState(
		history.location && history.location.state && history.location.state.filterContext
	);
	const [isLoading, setIsLoading] = React.useState({
		pdf: false,
		xml: false,
		excel: false,
		ec: false,
	});

	const [confirmObject, setConfirmObject] = React.useState({
		message: t("deleteMessage"),
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	useEffect(() => {
		if (localStorage.getItem("invoice_filter")) {
			const storedFilter = JSON.parse(localStorage.getItem("invoice_filter"));
			dispatch(updateFilter(storedFilter));
		}
	}, []);

	const onFilterChange = (newFilter) => {
		const filter = { ...filterInvoices, ...newFilter, pageNo: 0 };
		localStorage.setItem("invoice_filter", JSON.stringify(filter));
		dispatch(updateFilter(filter));

		if (props.fromDashboard) {
			dispatch(fetchInvoices({ ...filterInvoices }));
		} else if (
			!newFilter ||
			(newFilter && newFilter.searchBy === null && Object.keys(newFilter).length > 0) // the debouce adds searchBy property
		) {
			dispatch(fetchInvoices({ ...initialFilter, pageNo: filterContext?.pageNo }));
		}
	};

	const handleStartDateChange = (date, type) => {
		if (type === ISSUE_DATE) {
			setStartIssueDate(date);
			onFilterChange({ startIssueDate: date });
		} else if (type === SAVE_DATE) {
			setStartSaveDate(date);
			onFilterChange({ startSaveDate: date });
		} else {
			setStartCreatedDate(date);
			onFilterChange({ startCreatedDate: date });
		}
	};

	const handleEndDateChange = (date, type) => {
		if (type === ISSUE_DATE) {
			setEndIssueDate(date);
			onFilterChange({ endIssueDate: date });
		} else if (type === SAVE_DATE) {
			setEndSaveDate(date);
			onFilterChange({ endSaveDate: date });
		} else {
			setEndCreatedDate(date);
			onFilterChange({ endCreatedDate: date });
		}
	};

	const handleChangeStatus = (event) => {
		let newLst = [...event.target.value];
		newLst = newLst?.map((s) => {
			if (!s?.code) return props.statusList?.find((tmps) => s === tmps.code);
			return s;
		});
		onFilterChange({ status: newLst });
	};

	const handleChangeIntegratedFilter = (event) => {
		setSelectedPeriodIntegration(event.target.value);
		onFilterChange({ periodIntegrated: event.target.value });
	};
	const getDataFromSettings = (data, code) => data.find((e) => e.code === code);
	const handleChangeExportedFilter = (event) => {
		if (event.target.value) {
			setSelectedPeriodExported(event.target.value);
			onFilterChange({
				status: [getDataFromSettings(props.statusList, "EXPORTED")],
				periodExported: event.target.value,
			});
		} else {
			setSelectedPeriodExported("");
			onFilterChange({
				status: [],
				periodExported: "",
			});
		}
	};

	const handleChangeSortBy = (event) => {
		setSortBy(event.target.value);
		onFilterChange({ sortBy: event.target.value });
	};
	useEffect(() => {
		setCheckedAll(
			idInvoices &&
				props.selected &&
				props.selected.length === idInvoices.length &&
				idInvoices.length !== 0
		);
		setCheckedindeterminate(
			idInvoices &&
				props.selected &&
				!isEmpty(props.selected) &&
				props.selected.length < idInvoices.length
		);
	}, [props.selected]);

	useEffect(() => {
		props.fetchListStatus();
		if (filterInvoices) {
			setSelectedPeriodExported(filterInvoices.periodExported);
			setSelectedPeriodIntegration(filterInvoices.periodIntegrated);
			setSearchField(filterInvoices.searchBy);
		}
		setStartIssueDate(filterInvoices.startIssueDate);
		setStartSaveDate(filterInvoices.startSaveDate);
		setStartCreatedDate(filterInvoices.startCreatedDate);
		setEndIssueDate(filterInvoices.endIssueDate);
		setEndSaveDate(filterInvoices.endSaveDate);
		setEndCreatedDate(filterInvoices.endCreatedDate);

		setSortBy(filterInvoices.sortBy);
		const interval = setInterval(() => {
			props.fetchInvoices(filterInvoices);
			dispatch(invoicesTotalAmounts([]));
			dispatch(fetchCountInvoices());
			dispatch(fetchInvoiceCuttings());
		}, 20000);
		return () => clearInterval(interval);
	}, [props.invoices]);

	const handleCloseDeletePopup = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false });
	};

	const selectAllItems = () => {
		setSelectAll(true);
		setDeleteAll(true);
		dispatch(updateFilter({ ...filterInvoices, selectAll: true }));
	};

	const toDelete = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		dispatch(
			deleteInvoices(
				props.selected,
				getDataFromSettings(props.settings, "INVOICE_DELETE_All_ACTION").value === "1",
				deleteAll,
				filterInvoices.searchBy,
				filterInvoices.status,
				filterInvoices.startIssueDate,
				filterInvoices.startSaveDate,
				filterInvoices.startCreatedDate,
				filterInvoices.endIssueDate,
				filterInvoices.endSaveDate,
				filterInvoices.endCreatedDate,
				filterInvoices.extractionLvl,
				filterInvoices.duplicated,
				filterInvoices.periodExported,
				filterInvoices.periodIntegrated,
				handleCloseDeletePopup()
			)
		).then((response) => {
			if (!isEmpty(response.payload)) {
				toast.error(`${t("InvoicesCannotBeDeleted")} : ${response.payload.join(", ")}`);
			}
			dispatch(initFilter());
			dispatch(fetchCountInvoices());
			dispatch(invoicesTotalAmounts([]));
			props.setSelected([]);
			setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
		});
	};

	const debouncer = useCallback(
		debounce((nextValue) => onFilterChange({ searchBy: nextValue }, 500)),
		[]
	);

	const handleFilterValueChange = (e) => {
		setSearchField(e);
		debouncer(e);
	};

	return (
		<>
			<ConfirmDialog
				open={confirmObject.openConfirm}
				message={confirmObject.message}
				onCancel={handleCloseDeletePopup}
				onConfirm={toDelete}
				isLoading={confirmObject.isLoading}
			/>
			<Grid
				container
				style={{
					width: "99%",
				}}
			>
				<Grid container justify="center" spacing={1}>
					<Grid item xs="auto">
						<SearchFilter
							callback={(val) => handleFilterValueChange(val)}
							placeHolder={t("SearchInvoice")}
							style={classes.searchIcon}
							value={searchField != null ? searchField : ""}
						/>
					</Grid>
					<Grid item xs justify="center">
						<FormControl fullWidth>
							<InputLabel id="status">{t("Status")}</InputLabel>
							<Select
								labelId="status"
								id="statusId"
								multiple
								value={filterInvoices?.status.map((s) => s.code)}
								onChange={handleChangeStatus}
								input={<Input />}
								renderValue={(selected) =>
									props?.statusList
										?.filter((value) => selected?.includes(value?.code))
										?.map((s) => t(s?.label))
										.join(", ")
								}
							>
								{props.statusList.map((row) => (
									<MenuItem value={row.code}>
										<Checkbox
											checked={filterInvoices?.status?.map((s) => s?.code)?.indexOf(row.code) > -1}
										/>
										<ListItemText primary={t(row.label)} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs justify="center">
						<FormControl fullWidth>
							<InputLabel id="exported">{t("Exported")}</InputLabel>
							<Select
								labelId="exportedFilterPeriodId"
								id="exportedFilterByPeriod"
								value={selectedPeriodExported}
								input={<Input />}
								onChange={handleChangeExportedFilter}
								renderValue={(selected) => t(selected)}
							>
								{!isEmpty(selectedPeriodExported) && (
									<MenuItem value="">
										<Grid container direction="row" style={{ color: "gray" }}>
											<ListItemText primary="Enlever le filtre" />
											<CloseIcon />
										</Grid>
									</MenuItem>
								)}
								<MenuItem key="0" value="day">
									<ListItemText primary={t("day")} />
								</MenuItem>
								<MenuItem key="1" value="week">
									<ListItemText primary={t("week")} />
								</MenuItem>
								<MenuItem key="2" value="month">
									<ListItemText primary={t("month")} />
								</MenuItem>
								<MenuItem key="3" value="year">
									<ListItemText primary={t("year")} />
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs justify="center">
						<FormControl fullWidth>
							<InputLabel id="integrated">{t("Integrated")}</InputLabel>
							<Select
								labelId="integratedFilterPeriodId"
								id="integratedFilterByPeriod"
								value={selectedPeriodIntegration}
								input={<Input />}
								onChange={handleChangeIntegratedFilter}
								renderValue={(selected) => t(selected)}
							>
								{selectedPeriodIntegration && !isEmpty(selectedPeriodIntegration) && (
									<MenuItem value="">
										<Grid container direction="row" style={{ color: "gray" }}>
											<ListItemText primary="Enlever le filtre" />
											<CloseIcon />
										</Grid>
									</MenuItem>
								)}
								<MenuItem key="0" value="day">
									<ListItemText primary={t("day")} />
								</MenuItem>
								<MenuItem key="1" value="week">
									<ListItemText primary={t("week")} />
								</MenuItem>
								<MenuItem key="2" value="month">
									<ListItemText primary={t("month")} />
								</MenuItem>
								<MenuItem key="3" value="year">
									<ListItemText primary={t("year")} />
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs justify="center">
						<FormControl fullWidth>
							<InputLabel id="sortBy">{t("sortBy")}</InputLabel>
							<Select
								labelId="sortBy"
								id="sortById"
								value={sortBy}
								input={<Input />}
								onChange={handleChangeSortBy}
								renderValue={(selected) => t(selected)}
							>
								{!isEmpty(sortBy) && (
									<MenuItem value="">
										<Grid container direction="row" style={{ color: "gray" }}>
											<ListItemText primary="Enlever le filtre" />
											<CloseIcon />
										</Grid>
									</MenuItem>
								)}
								{columns.map((row, i) => (
									<MenuItem key={i} value={row}>
										<ListItemText primary={t(row)} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item className={classes.filterEnd} alignItems="end" spacing={3}>
						<Tooltip title={t("dates_filter")} placement="top" arrow>
							<IconButton
								className={classes.dateFilters}
								onClick={(e) => setDateFilterAnchorEl(e.currentTarget)}
							>
								<DateRange />
							</IconButton>
						</Tooltip>
						<Popover
							id="filter-more-popover"
							open={Boolean(dateFilterAnchorEl)}
							anchorEl={dateFilterAnchorEl}
							onClose={() => setDateFilterAnchorEl(null)}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							className={classes.dateFilterPopover}
						>
							<Box p={1}>
								<Typography className={classes.dateRangeLabel} gutterBottom>
									{`${t("issueDate")}:`}
								</Typography>
								<DateRangePicker
									start={startIssueDate ? new Date(startIssueDate) : ""}
									end={endIssueDate ? new Date(endIssueDate) : ""}
									onStartChange={(val) => handleStartDateChange(val, ISSUE_DATE)}
									onEndChange={(val) => handleEndDateChange(val, ISSUE_DATE)}
								/>
							</Box>
							<Box p={1}>
								<Typography className={classes.dateRangeLabel} gutterBottom>
									{`${t("createdDate")}:`}
								</Typography>
								<DateRangePicker
									start={startCreatedDate ? new Date(startCreatedDate) : ""}
									end={endCreatedDate ? new Date(endCreatedDate) : ""}
									onStartChange={(val) => handleStartDateChange(val, CREATE_DATE)}
									onEndChange={(val) => handleEndDateChange(val, CREATE_DATE)}
								/>
							</Box>
							<Box p={1}>
								<Typography className={classes.dateRangeLabel} gutterBottom>
									{`${t("saveDate")}:`}
								</Typography>
								<DateRangePicker
									start={startSaveDate ? new Date(startSaveDate) : ""}
									end={endSaveDate ? new Date(endSaveDate) : ""}
									onStartChange={(val) => handleStartDateChange(val, SAVE_DATE)}
									onEndChange={(val) => handleEndDateChange(val, SAVE_DATE)}
								/>
							</Box>
						</Popover>
					</Grid>
					<Grid style={{ marginTop: 15 }}>
						<Grid container style={{ justifyContent: "space-between" }}>
							<Grid item>
								{props.settings &&
									getDataFromSettings(props.settings, "INVOICE_RELAUNCH_EXTRACTION")?.value ===
										"1" &&
									!isEmpty(props.selected) && (
										<HasPermission menuName="Invoices list" actionName="Relaunch extraction">
											<Tooltip placement="top" title={t("tooltip.action.relaunch_extraction")}>
												<IconButton
													aria-label="relaunch"
													disabled={isEmpty(props.selected)}
													onClick={() => {
														dispatch(relaunchInvoices(props.selected)).then((response) => {
															if (!isEmpty(response.data)) {
																toast.error(
																	`${t("InvoicesCannotBeRelaunched")} : ${response.data.join(", ")}`
																);
															}
															dispatch(fetchInvoices());
															props.setSelected([]);
														});
													}}
												>
													<LaunchIcon />
												</IconButton>
											</Tooltip>
										</HasPermission>
									)}
							</Grid>
							<Grid item>
								{props.settings &&
									getDataFromSettings(props.settings, "INVOICE_EXPORT_XML")?.value === "1" &&
									!isEmpty(props.selected) && (
										<HasPermission menuName="Invoices list" actionName="Export xml">
											<Tooltip placement="top" title={t("tooltip.action.export_xml")}>
												<IconButton
													aria-label="exportXML"
													disabled={isEmpty(props.selected)}
													onClick={() => {
														setIsLoading({ ...isLoading, xml: true });
														dispatch(downloadInvoicesXML(props.selected, filterInvoices)).then(
															() => {
																props.setSelected([]);
																setIsLoading({ ...isLoading, xml: false });
															}
														);
													}}
												>
													<LoadingButton isLoading={isLoading.xml}>
														<ImportExportIcon />
													</LoadingButton>
												</IconButton>
											</Tooltip>
										</HasPermission>
									)}
							</Grid>
							<Grid item>
								{props.settings &&
									getDataFromSettings(props.settings, "INVOICE_EXPORT_PDF")?.value === "1" &&
									!isEmpty(props.selected) && (
										<HasPermission menuName="Invoices list" actionName="Download">
											<Tooltip placement="top" title={t("tooltip.action.download")}>
												<IconButton
													aria-label="download"
													disabled={isEmpty(props.selected)}
													onClick={() => {
														setIsLoading({ ...isLoading, pdf: true });
														dispatch(
															downloadInvoices(props.selected, "inv", filterInvoices, selectAll)
														).then(() => {
															props.setSelected([]);
															setIsLoading({ ...isLoading, pdf: false });
														});
													}}
												>
													<LoadingButton isLoading={isLoading.pdf}>
														<GetAppIcon />
													</LoadingButton>
												</IconButton>
											</Tooltip>
										</HasPermission>
									)}
							</Grid>
							<Grid item>
								{props.settings &&
									getDataFromSettings(props.settings, "INVOICE_EXPORT_EXCEL")?.value === "1" &&
									!isEmpty(props.selected) && (
										<HasPermission menuName="Invoices list" actionName="Export excel">
											<Tooltip placement="top" title={t("tooltip.action.export_excel")}>
												<IconButton
													aria-label="download"
													disabled={isEmpty(props.selected)}
													onClick={() => {
														setIsLoading({ ...isLoading, excel: true });
														dispatch(
															downloadInvoicesExcel(props.selected, "", filterInvoices, selectAll)
														).then(() => {
															props.setSelected([]);
															setIsLoading({ ...isLoading, excel: false });
														});
													}}
												>
													<LoadingButton isLoading={isLoading.excel}>
														<DescriptionIcon />
													</LoadingButton>
												</IconButton>
											</Tooltip>
										</HasPermission>
									)}
							</Grid>
							<Grid item>
								{props.settings &&
									getDataFromSettings(props.settings, "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY")
										?.value === "1" &&
									!isEmpty(props.selected) && (
										<HasPermission menuName="Invoices list" actionName="Export Excel Journal Entry">
											<Tooltip
												placement="top"
												title={t("tooltip.action.export_entry_account_excel")}
											>
												<IconButton
													aria-label="download"
													disabled={isEmpty(props.selected)}
													onClick={() => {
														setIsLoading({ ...isLoading, ec: true });
														dispatch(
															exportAccountingEntriesExcel(props.selected, filterInvoices)
														).then(() => {
															props.setSelected([]);
															setIsLoading({ ...isLoading, ec: false });
														});
													}}
												>
													<LoadingButton isLoading={isLoading.ec}>
														<AccountBalanceWalletIcon />
													</LoadingButton>
												</IconButton>
											</Tooltip>
										</HasPermission>
									)}
							</Grid>

							<Grid item style={{ marginTop: 5 }}>
								{(isActionAllowed(currentUserPermissions, "Invoices list", "Relaunch extraction") ||
									isActionAllowed(currentUserPermissions, "Invoices list", "Delete all invoices") ||
									isActionAllowed(currentUserPermissions, "Invoices list", "Download") ||
									isActionAllowed(currentUserPermissions, "Invoices list", "Export xml") ||
									isActionAllowed(currentUserPermissions, "Invoices list", "Export excel") ||
									isActionAllowed(
										currentUserPermissions,
										"Invoices list",
										"Export Excel Journal Entry"
									)) && (
									<Checkbox
										checked={checkedAll}
										indeterminate={checkedindeterminate}
										onChange={(e) => {
											props.handleSelectedAll(e.target.checked);
											setSelectAll(false);
										}}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{checkedAll && (
				<Card className={classes.selectAll}>
					<CardContent>
						<Grid container direction="row" justify="center" alignItems="center">
							{selectAll ? (
								<Grid item xs>
									<Typography className={classes.title} gutterBottom align="center">
										{t("invoice.selected.title0")}
									</Typography>
								</Grid>
							) : (
								<>
									<Grid item xs={3}>
										<Typography
											color="textSecondary"
											align="right"
											// gutterBottom
										>
											{t("invoice.selected.title1")}
											{props.selected.length}

											{t("invoice.selected.title2")}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Button onClick={() => selectAllItems()}>
											<Typography
												className={classes.title}
												align="center"
												justify="center"
												color="secondary"
											>
												{t("invoice.selected.title3")}
											</Typography>
										</Button>
									</Grid>
								</>
							)}
						</Grid>
					</CardContent>
				</Card>
			)}
		</>
	);
}

const mapStateToProps = (state) => {
	const dataQueris = state.requests.queries;
	return {
		invoices: dataQueris.LIST_FETCH_INVOICE && dataQueris.LIST_FETCH_INVOICE.data,
		statusList: get(dataQueris, "LIST_FETCH_STATUS.data.invoiceStatusList", []),
	};
};
export default connect(mapStateToProps, { fetchInvoices, fetchListStatus })(Filter);
