import React, { useEffect, useMemo } from "react";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDownOutlined";
import Popover from "@material-ui/core/Popover";
import ListItemText from "@material-ui/core/ListItemText";
import CardActionArea from "@material-ui/core/CardActionArea";
import AppBar from "@material-ui/core/AppBar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";
import {
	currencyFormat,
	isUserAdminOnly,
	isSupplierOnly,
	isClientOnly,
	isPurchaseOrder,
	isSimpleUserOnly,
	REGEX,
} from "utils";
import { isMenuAllowed } from "components/HasPermission";
import HeaderToolbar from "./HeaderToolbar";
import useStyles from "./style";
import logo from "../../logo.png";
import CardHeader from "./CardHeader";
import {
	fetchCountSuppliers,
	fetchCountInvoices,
	invoicesTotalAmounts,
	invoicesTotalAmountsOverDue,
	logout,
} from "../../../redux/actions/invoices";
import {
	fetchCountClients,
	fetchCountOrders,
	ordersTotalAmounts,
	ordersTotalVATAmounts,
} from "../../../redux/actions/orders";
import { invoicesVATAmountsBySupplier } from "../../../redux/actions/suppliers";

export default () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const changePassPage = history.location.pathname.match(REGEX.CHANGE_PASSWORD);
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const env = isOrderEnv ? "orders" : "invoices";

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isSupplier, setIsSupplier] = React.useState(false);
	const [isClient, setIsClient] = React.useState(false);

	const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);

	const invoiceTotalAmounts = useSelector(
		({ requests }) =>
			requests.queries.INVOICE_TOTAL_AMOUNT && requests.queries.INVOICE_TOTAL_AMOUNT.data
	);

	const orderTotalAmounts = useSelector(
		({ requests }) =>
			requests.queries.ORDER_TOTAL_AMOUNT && requests.queries.ORDER_TOTAL_AMOUNT.data
	);

	const newSuppliers = useSelector(
		({ requests }) =>
			requests.queries.FETCH_COUNT_SUPPLIERS && requests.queries.FETCH_COUNT_SUPPLIERS.data
	);

	const newClients = useSelector(
		({ requests }) =>
			requests.queries.FETCH_COUNT_CLIENTS && requests.queries.FETCH_COUNT_CLIENTS.data
	);

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);

	const totalInvoices = useSelector(({ requests }) => get(requests, "queries.TOTAL_INVOICE.data"));
	const totalOrders = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_COUNT_ORDERS.data")
	);

	// const invoicesTotal = useSelector(({ requests }) =>
	// 	get(requests, "queries.INVOICE_VAT_AMOUNT_BY_SUPPLIER.data")
	// );

	const invoicesTotal = useSelector(({ requests }) =>
		get(requests, "queries.INVOICE_TOTAL_AMOUNT_BY_SUPPLIER.data")
	);

	const ordersTotal = useSelector(({ requests }) =>
		get(requests, "queries.ORDER_TOTAL_AMOUNT.data")
	);

	const getUserSupplierId = () => {
		if (get(currentUser, "roles") && currentUser.roles.includes("invoice_supplier")) {
			return currentUser.supplierId;
		}
		return undefined;
	};

	useEffect(() => {
		if (!get(currentUser, "roles")) {
			return;
		}
		if (isSupplierOnly(get(currentUser, "roles", []))) {
			dispatch(invoicesVATAmountsBySupplier(getUserSupplierId(currentUser)));
			setIsSupplier(true);
		}

		if (isClientOnly(get(currentUser, "roles", []))) {
			setIsClient(true);
		}

		if (
			get(currentUser, "roles") &&
			(currentUser.roles.includes("invoice_user") ||
				currentUser.roles.includes("invoice_supplier") ||
				currentUser.roles.includes("manager"))
		) {
			dispatch(
				invoicesTotalAmounts(
					[
						"INTEGRATION",
						"TO_VERIFY",
						"TO_CONTROL",
						"TO_PAY",
						"PAYED",
						"REJECTED",
						"CANCELED",
						"EXCEPTION",
						"TO_VALIDATE_EXTERNAL",
					],
					"_UNPAID"
				)
			);
			dispatch(invoicesTotalAmounts(["EXPORTED"], "_PAID"));
			dispatch(fetchCountSuppliers());
			dispatch(invoicesTotalAmounts());
			dispatch(invoicesTotalAmountsOverDue());
			dispatch(fetchCountInvoices());
		}
		if (
			get(currentUser, "roles") &&
			(currentUser.roles.includes("order_client") ||
				currentUser.roles.includes("order_user") ||
				currentUser.roles.includes("manager")) &&
			isOrderEnv
		) {
			dispatch(ordersTotalAmounts(["EXPORTED"], "_PAID"));
			dispatch(fetchCountClients());
			dispatch(ordersTotalAmounts());
			dispatch(fetchCountOrders());
			dispatch(ordersTotalVATAmounts());
		}
	}, [currentUser, env]);

	useEffect(() => {
		if (isSupplierOnly(get(currentUser, "roles", []))) {
			dispatch(invoicesVATAmountsBySupplier(getUserSupplierId(currentUser)));
			setIsSupplier(true);
		}
	}, [currentUser, env]);

	const getOrdersCount = () => totalOrders;

	// const getInvoicesTotal = () => {
	// 	if (get(currentUser, "roles") && currentUser.roles.includes("invoice_supplier")) {
	// 		return invoicesTotal;
	// 	}
	// 	return invoiceTotalAmounts;
	// };

	const getInvoiceTotalAmount = () => {
		if (
			get(currentUser, "roles") &&
			currentUser.roles.includes("invoice_supplier") &&
			!isUndefined(invoicesTotal)
		) {
			return invoicesTotal;
		}
		return invoiceTotalAmounts;
	};

	const getOrdersTotal = () => {
		if (get(currentUser, "roles") && currentUser.roles.includes("order_user")) {
			return ordersTotal;
		}
		return orderTotalAmounts;
	};

	const getUserRole = () => {
		if (get(currentUser, "roles") && currentUser.roles.includes("root_administrator")) {
			return t("Administrator");
		}
		if (get(currentUser, "roles") && currentUser.roles.includes("invoice_user")) {
			return t("agent_accounting");
		}
		if (get(currentUser, "roles") && currentUser.roles.includes("order_user")) {
			return t("agent_purchase");
		}
		if (get(currentUser, "roles") && currentUser.roles.includes("invoice_supplier")) {
			return t("supplier");
		}
		if (get(currentUser, "roles") && currentUser.roles.includes("order_client")) {
			return t("client");
		}
		if (get(currentUser, "roles") && currentUser.roles.includes("manager")) {
			return t("manager");
		}
		return "";
	};

	const getUserInitials = () => {
		const f = get(currentUser, "firstName") ? currentUser.firstName.charAt(0).toUpperCase() : "";
		const l = get(currentUser, "lastName") ? currentUser.lastName.charAt(0).toUpperCase() : "";

		return f + l;
	};

	return (
		<AppBar
			position="relative"
			color="transparent"
			className={clsx(classes.root, !headerExpanded && classes.rootSticky)}
			elevation={24}
		>
			<Grid container className={classes.gridContainer} alignItems="center" justify="space-between">
				<Grid item>
					<img src={logo} alt="Logo" className={classes.logo} />
				</Grid>

				{env === "invoices" &&
					!changePassPage &&
					!isUserAdminOnly(get(currentUser, "roles", [])) &&
					!isClientOnly(get(currentUser, "roles", [])) &&
					!isPurchaseOrder(get(currentUser, "roles", [])) && (
						<Grid item className="d-flex">
							{isMenuAllowed(currentUserPermissions, "Invoices list") && (
								<CardHeader
									title={
										getInvoiceTotalAmount(currentUser)
											? `${currencyFormat(getInvoiceTotalAmount(currentUser))} €`
											: `${currencyFormat(0)} €`
									}
									content={t("Total of expenses")}
									path="/invoices"
									isSupplier={isSupplier}
									rounded
								/>
							)}

							{!isSupplierOnly(get(currentUser, "roles", [])) && (
								<CardHeader
									path="/supplier"
									title={newSuppliers || 0}
									content={t("New suppliers")}
									rounded
								/>
							)}
							<CardHeader
								title={totalInvoices || 0}
								content={t("invoice.total_invoice")}
								path="/invoices"
								isSupplier={isSupplier}
								rounded
							/>
						</Grid>
					)}
				{env === "orders" &&
					!changePassPage &&
					!isUserAdminOnly(get(currentUser, "roles", [])) &&
					!isSupplierOnly(get(currentUser, "roles", [])) &&
					!isSimpleUserOnly(get(currentUser, "roles", [])) && (
						<Grid item className="d-flex">
							{isMenuAllowed(currentUserPermissions, "Orders list") && (
								<CardHeader
									title={
										getOrdersTotal(currentUser)
											? `${currencyFormat(getOrdersTotal(currentUser))} €`
											: `${currencyFormat(0)} €`
									}
									content={t("Total of order expenses")}
									path="/orders"
									isClient={isClient}
									rounded
								/>
							)}
							{!isClientOnly(get(currentUser, "roles", [])) && (
								<CardHeader
									path="/client"
									title={newClients || 0}
									content={t("New clients")}
									rounded
								/>
							)}
							<CardHeader
								title={getOrdersCount(currentUser) || 0}
								content={t("order.total_invoice")}
								path="/orders"
								isClient={isClient}
								rounded
							/>
						</Grid>
					)}
				<Grid item className="text-center">
					<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
						<Badge
							overlap="circle"
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							badgeContent={<ArrowDropDown color="secondary" />}
						>
							<Avatar className={classes.purple}>{getUserInitials()}</Avatar>
						</Badge>
					</IconButton>
					<Typography variant="subtitle2" align="center" className={classes.title}>
						{get(currentUser, "fullName")}
					</Typography>
					<Typography variant="caption" align="center" className={classes.title}>
						{getUserRole()}
					</Typography>
				</Grid>

				<Popover
					id="profile-popover"
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<CardActionArea onClick={() => history.push("/profile")}>
						<ListItemText primary={t("Profile")} className={classes.popupItems} />
					</CardActionArea>
					<CardActionArea onClick={() => dispatch(logout())}>
						<ListItemText primary={t("Logout")} className={classes.popupItems} />
					</CardActionArea>
				</Popover>
			</Grid>
			<HeaderToolbar />
		</AppBar>
	);
};
